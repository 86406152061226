import { Box, Divider, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/config';
import { useAppSelector } from '../../store/hooks';
import { User } from '../../types/user.types';

function UserInformationList() {
  const { t } = useTranslation();
  const user: User = useAppSelector((state: RootState) => state.user);

  return (
    <Paper elevation={0} id="user-list-paper">
      <h6 className="subtitle">{t('user-information.subtitle')}</h6>
      <Box className="user-list-row">
        <Typography variant="body2">{t('user-information.username')}</Typography>
        <Typography variant="body1">{user.userName}</Typography>
      </Box>
      <Divider />
      <Box className="user-list-row">
        <Typography variant="body2">{t('user-information.email')}</Typography>
        <Typography variant="body1">{user.email}</Typography>
      </Box>
      <Divider />
      <Box className="user-list-row">
        <Typography variant="body2">{t('user-information.document')}</Typography>
        <Typography variant="body1">{`${user.documentType} ${user.document}`}</Typography>
      </Box>
      <Divider />
      <Box className="user-list-row">
        <Typography variant="body2">{t('user-information.phone')}</Typography>
        <Typography variant="body1">{user.phone}</Typography>
      </Box>
    </Paper>
  );
}

export default UserInformationList;
