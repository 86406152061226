import { TFunction } from 'i18next';
import BuildingIcon from '../components/Icons/BuildingIcon';
import CutleryIcon from '../components/Icons/CutleryIcon';
import HotelIcon from '../components/Icons/HotelIcon';
import ServiceIcon from '../components/Icons/ServiceIcon';
import SuitcaseIcon from '../components/Icons/SuitcaseIcon';
import i18next from '../i18n/config';
import { RootState } from '../store/config';
import { VerticalType } from '../types/enums';

const t: TFunction = i18next.t.bind(i18next);

// TODO: Remove this helper and use vertical.name
const getVerticalTitle = (verticalType: VerticalType | undefined) => {
  switch (verticalType) {
    case VerticalType.EXPENSES:
      return 'Gastos Comunes';
    case VerticalType.RESTAURANTS:
      return 'Restaurantes';
    case VerticalType.TRAVEL_AGENCY:
      return 'Agencias de Viaje';
    case VerticalType.HOTELS:
      return 'Hoteles';
    case VerticalType.SERVICES:
      return 'Servicios';
    default:
      return null;
  }
};

// TODO: Remove this helper and use vertical.name
const getVerticalPaymentTitle = (verticalType: VerticalType | undefined) => {
  switch (verticalType) {
    case VerticalType.EXPENSES:
      return 'Gastos Comunes';
    case VerticalType.RESTAURANTS:
      return 'Restaurantes';
    case VerticalType.TRAVEL_AGENCY:
      return 'Agencia de Viaje';
    case VerticalType.HOTELS:
      return 'Hotel';
    case VerticalType.SERVICES:
      return 'Comercio';
    default:
      return null;
  }
};

const getVerticalSubtitle = (verticalType: VerticalType | undefined) => {
  switch (verticalType) {
    case VerticalType.EXPENSES:
      return 'Pagos';
    case VerticalType.RESTAURANTS:
      return 'Cobro con Pix';
    case VerticalType.TRAVEL_AGENCY:
      return 'Pagos';
    case VerticalType.HOTELS:
      return 'Pagos';
    case VerticalType.SERVICES:
      return 'Pagos';
    default:
      return 'Pagos';
  }
};

const getVerticalIcon = (verticalType: VerticalType | undefined, selected: boolean) => {
  switch (verticalType) {
    case VerticalType.EXPENSES:
      return <BuildingIcon selected={selected} />;
    case VerticalType.RESTAURANTS:
      return <CutleryIcon selected={selected} />;
    case VerticalType.TRAVEL_AGENCY:
      return <SuitcaseIcon selected={selected} />;
    case VerticalType.HOTELS:
      return <HotelIcon selected={selected} />;
    case VerticalType.SERVICES:
      return <ServiceIcon selected={selected} />;
    default:
      return null;
  }
};

const getVerticalLinkError = (verticalType: VerticalType | undefined) => {
  switch (verticalType) {
    case VerticalType.EXPENSES:
      return t('verticals.expenses.link-error');
    case VerticalType.RESTAURANTS:
      return t('verticals.restaurants.link-error');
    case VerticalType.TRAVEL_AGENCY:
      return t('verticals.travel_agency.link-error');
    case VerticalType.HOTELS:
      return t('verticals.hotels.link-error');
    case VerticalType.SERVICES:
      return t('verticals.services.link-error');
    default:
      return t('verticals.link-error');
  }
};

const getVerticalDebt = (verticalType: VerticalType, state: RootState) => {
  switch (verticalType) {
    case VerticalType.HOTELS:
      return {
        debtState: state.debt.hotelsDebt,
      };
    case VerticalType.RESTAURANTS:
      return {
        debtState: state.debt.restaurantsDebt,
      };
    case VerticalType.EXPENSES:
      return {
        debtState: state.debt.expensesDebt,
      };
    case VerticalType.TRAVEL_AGENCY:
      return {
        debtState: state.debt.travelsDebt,
      };
    case VerticalType.SERVICES:
      return {
        debtState: state.debt.servicesDebt,
      };
    default:
      return null;
  }
};

export const verticalHelper = {
  getVerticalTitle,
  getVerticalSubtitle,
  getVerticalIcon,
  getVerticalPaymentTitle,
  getVerticalLinkError,
  getVerticalDebt,
};
