import { Box } from '@mui/material';
import { paymentLinkStepsLabels, stepsLabels } from '../../../data/paymentSteps';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import { PaymentStep } from '../../../types/enums';
import CustomStepper from '../../Stepper/CustomStepper';
import EmailStep from '../../Stepper/EmailStep';
import PaymentInformationStep from '../../Stepper/PaymentInformationStep';
import PaymentMethodsStep from '../../Stepper/PaymentMethodsStep';
import ExpensesHeader from './ExpensesHeader';
import PaymentDetails from './PaymentDetails';
import PaymentInformation from './PaymentInformation';

function PaymentStepper() {
  const { step, isPaymentLink } = usePaymentStepper();
  return (
    <>
      <ExpensesHeader />
      <Box className="screen stepper-paper">
        <CustomStepper activeStep={step} stepLabels={isPaymentLink ? paymentLinkStepsLabels : stepsLabels} />
        {step === PaymentStep.INFORMATION && <PaymentInformationStep children={<PaymentInformation />} />}
        {step === PaymentStep.PAYMENT_METHODS && <PaymentMethodsStep children={<PaymentDetails />} />}
        {isPaymentLink && step === PaymentStep.EMAIL && <EmailStep />}
      </Box>
    </>
  );
}

export default PaymentStepper;
