import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as PayPuntaLogo } from '../../assets/isologo-paypunta-color.svg';
import { RootState } from '../../store/config';
import { useAppSelector } from '../../store/hooks';
import { VerticalType } from '../../types/enums';
import { Vertical } from '../../types/verticals.type';
import { verticalHelper } from '../../utils/vertical.helper';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

interface LoginLayoutProps {
  formTitle: string;
  form: JSX.Element;
  contentClass?: string;
  formClass?: string;
}

function LoginLayout({ formTitle, form, contentClass, formClass }: LoginLayoutProps) {
  const { t } = useTranslation();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const params = useParams();
  const verticalTypeParam = params.verticalType?.toUpperCase() as VerticalType;

  return (
    <Box id="login">
      <Box className="background-container">
        <Box className="background-1" />
      </Box>
      <Box className="language-selector-container">
        <LanguageSelector />
      </Box>
      <Box className={`login-content ${contentClass}`}>
        <Typography variant="h1">
          <Trans i18nKey={`verticals.${vertical?.verticalType.toLowerCase()}.welcome-message`}>
            Te damos la bienvenida al portal de
            <br />
            <strong>
              <div>Paypunta</div>
              <div>{verticalHelper.getVerticalTitle(vertical?.verticalType || verticalTypeParam)}</div>
            </strong>
          </Trans>
        </Typography>
        <div className={`login-form ${formClass}`}>
          <PayPuntaLogo />
          <p className="form-title">{t(`verticals.${vertical?.verticalType.toLowerCase()}.login-title`)}</p>
          {form}
        </div>
      </Box>
    </Box>
  );
}

export default LoginLayout;
