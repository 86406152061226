import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DownLoadIcon } from '../assets/file-download.svg';
import { ReactComponent as PayPuntaWhiteLogo } from '../assets/logo-pp-white.svg';
import { ReactComponent as UsdIcon } from '../assets/usd-circle.svg';
import Message from '../components/Message';
import { AppDispatch, RootState } from '../store/config';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { resetCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep } from '../store/slices/step.slice';
import { VerticalType } from '../types/enums';
import { PaymentResponse } from '../types/paymentResult.types';

function Success() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const paymentSuccess: PaymentResponse | null = useAppSelector((state: RootState) => state.payment.payment);
  const isExpenses: boolean = paymentSuccess?.verticalType === VerticalType.EXPENSES;
  const isRestaurants: boolean = paymentSuccess?.verticalType === VerticalType.RESTAURANTS;

  const title: string = isExpenses ? t('success.title-expenses') : t('success.title-others');

  const text: string = isExpenses ? t('success.subtitle-expenses') : '';

  useEffect(() => {
    if (!paymentSuccess?.data?.paymentId) navigate('/');
    if (paymentSuccess?.verticalType !== VerticalType.RESTAURANTS) {
      dispatch(resetCommerce());
    }
    dispatch(resetDebtState());
    dispatch(resetStep());
  }, [dispatch, navigate, paymentSuccess?.data?.paymentId, paymentSuccess?.verticalType]);

  return (
    <Message
      id="success-payment"
      payPuntaLogo={<PayPuntaWhiteLogo />}
      title={title}
      text={text}
      downloadIcon={<DownLoadIcon />}
      downloadText={t('success.downloadText')}
      retryIcon={<UsdIcon />}
      retryText={isExpenses ? t('success.retryText-expenses') : t('success.retryText-others')}
      retryUrl={
        isRestaurants
          ? `/${VerticalType.RESTAURANTS.toLowerCase()}`
          : `/${paymentSuccess?.verticalType.toLowerCase()}/login`
      }
    />
  );
}

export default Success;
