import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname }: Location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 400);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
