import { VerticalType } from '../types/enums';
import { PaymentMethodsParameters } from '../types/paymentMethod.types';
import axios from './axios';

const getPaymentMethods = async (paymentMethodsParameters: PaymentMethodsParameters) => {
  try {
    const res = await axios.get(
      `/verticals/${VerticalType[paymentMethodsParameters.verticalType]}/commerce/${
        paymentMethodsParameters.commerceId
      }/payment-methods`,
    );

    return res.data.data;
  } catch (err: any) {
    throw err;
  }
};

export const paymentMethodsService = { getPaymentMethods };
