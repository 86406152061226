import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/isologo-paypunta-color.svg';
import Loading from '../components/Animations/Loading';
import LanguageSelector from '../components/LanguageSelector/LanguageSelector';
import VerticalBox from '../components/VerticalBox';
import useVerticals from '../hooks/queries/useVerticals';
import useWindowSize from '../hooks/useWindowSize';
import { resetCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep } from '../store/slices/step.slice';
import { setVertical } from '../store/slices/vertical.slice';
import { VerticalType } from '../types/enums';
import { Vertical } from '../types/verticals.type';

function Landing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [selectedVertical, setSelectedVertical] = useState<Vertical | null>(null);
  const { data: verticals, isLoading: loading, isError } = useVerticals();

  const filteredVerticals = verticals?.filter(
    (vertical) =>
      typeof VerticalType[vertical.verticalType as keyof typeof VerticalType] !== 'undefined' &&
      vertical.enabled,
  );

  const handleSelect = (selectedVertical: Vertical) => {
    setSelectedVertical(selectedVertical);
  };

  const handleClick = () => {
    if (selectedVertical !== null) {
      dispatch(setVertical(selectedVertical));
      navigate(`/${selectedVertical.verticalType.toLocaleLowerCase()}/login`);
    }
  };

  useEffect(() => {
    dispatch(resetCommerce());
    dispatch(resetDebtState());
    dispatch(resetStep());
  }, [dispatch]);

  if (loading) return <Loading height={300} width={350} centered />;

  return (
    <Box className="landing">
      <Grid container className={`landing-container ${isMobile ? 'mobile' : 'desktop'}`}>
        <Box className="language-selector-container">
          <LanguageSelector />
        </Box>
        <Box className="landing-content">
          <Grid item xs={12} md={6} className="landing-texts">
            <Grid
              item
              xs={12}
              container
              direction="column"
              alignItems="flex-start"
              className={!isMobile ? 'desktop-width' : 'mobile-width'}
            >
              <Typography variant="h2">{t('home-title')}</Typography>
              <Typography className="landing-instruction">{t('home-subtitle')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className="commerces-container">
            <Box>
              <img src={logo} alt="Logo" />
            </Box>
            <Typography className="vertical-selection">{t('home-menu-title')}</Typography>
            <Box className="commerces-boxes-container">
              {isError ? (
                <p>{t('errors.loading-verticals')}</p>
              ) : (
                filteredVerticals?.map((vertical) => (
                  <VerticalBox
                    key={vertical.verticalType}
                    vertical={vertical}
                    selected={selectedVertical?.verticalType === vertical.verticalType}
                    handleClick={handleSelect}
                  />
                ))
              )}
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={selectedVertical === null}
              onClick={handleClick}
            >
              {t('home-menu-submit-button')}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}

export default Landing;
