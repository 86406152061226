import { Step, StepLabel, Stepper, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/_variables.module.scss';

interface CustomStepperProps {
  activeStep: number;
  stepLabels: string[];
}

const stepperStyles: SxProps = {
  '& .MuiStepIcon-root.Mui-completed': {
    color: `${colors.accent} !important`,
  },
  '& .MuiStepIcon-root': {
    color: `${colors.lightGreyish} !important`,
    '& .MuiStepIcon-text': {
      fill: `${colors.darkGrey} !important`,
    },
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: `${colors.primary} !important`,
    '& .MuiStepIcon-text': {
      fill: `${colors.white} !important`,
    },
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: `${colors.lightGrey} !important`,
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: `${colors.lightGrey} !important`,
  },
  '& .MuiStep-horizontal': {
    paddingLeft: { md: '0' },
  },
};

function CustomStepper({ activeStep, stepLabels }: CustomStepperProps) {
  const { t } = useTranslation();
  return (
    <Stepper activeStep={activeStep} className="stepper-container" connector={null} sx={stepperStyles}>
      {stepLabels.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default CustomStepper;
