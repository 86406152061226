import { Button, ButtonProps, SxProps } from '@mui/material';
import colors from '../styles/_variables.module.scss';

interface PrimaryButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?(): void;
  className?: string;
  form?: string;
}

const styles: SxProps = {
  color: colors.white,
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      backgroundColor: colors.primaryLight,
      boxShadow: 'none',
    },
  },
  boxShadow: 'none',
  minWidth: '7rem',
};

function PrimaryButton({ children, className, onClick, form, ...rest }: PrimaryButtonProps) {
  const handleSubmit = () => onClick && onClick();

  return (
    <Button
      form={form}
      variant="contained"
      color="primary"
      className={className}
      onClick={handleSubmit}
      sx={styles}
      type="submit"
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
