import * as Sentry from '@sentry/react';
import { config } from '../config/config';

export function configureSentry() {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: config.env || 'local',
  });
}
