export enum VerticalType {
  EXPENSES = 'EXPENSES',
  RESTAURANTS = 'RESTAURANTS',
  TRAVEL_AGENCY = 'TRAVEL_AGENCY',
  HOTELS = 'HOTELS',
  SERVICES = 'SERVICES',
}

export enum PaymentStep {
  INFORMATION = 0,
  PAYMENT_METHODS = 1,
  EMAIL = 2,
  CONFIRMATION = 3,
}

export const mutationKeys = {
  getPaymentAmount: ['getPaymentAmount'] as const,
  beginPay: ['beginPay'] as const,
};
