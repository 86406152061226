import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Issuer } from '../types/paymentMethod.types';

interface SinglePaymentMethodProps {
  issuer?: Issuer | null;
}

function SinglePaymentMethod({ issuer }: SinglePaymentMethodProps) {
  const { t } = useTranslation();
  return (
    <Box className="single-payment-method">
      <img src={issuer?.issuerImage} alt={`${issuer?.issuerName} logo`} />
      <Box className="data">
        {t('single-payment-method')}
        <span>{issuer?.issuerName}</span>
      </Box>
    </Box>
  );
}

export default SinglePaymentMethod;
