import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { useField } from 'formik';
import { BaseSyntheticEvent, ChangeEvent, HTMLAttributes } from 'react';
import colors from '../../styles/_variables.module.scss';

export interface FormikTextFieldProps {
  name: string;
  type?: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  fullWidth?: boolean;
  showPasswordIcon?: boolean;
  value?: string | number | null;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  onChange?: (e: BaseSyntheticEvent) => void;
  formControlStyles?: SystemStyleObject<Theme>;
}

const baseFormControlStyles: SystemStyleObject<Theme> = {
  margin: (theme: Theme) => `${theme.spacing(1)} 0`,
};

const inputLabelStyles: SystemStyleObject<Theme> = {
  '&.MuiFormLabel-root, &.MuiFormLabel-root.Mui-focused': {
    color: colors.lightGrey,
    left: '-10px',
    font: 'normal normal normal 16px Rubik',
  },
};

const baseTextFieldStyles: SystemStyleObject<Theme> = {
  backgroundColor: colors.white,
  color: colors.lightGrey,
  font: 'normal normal normal 16px Rubik',
  '& .MuiOutlinedInput-root': {
    '& .Mui-disabled': {
      backgroundColor: colors.disabledGrey,
      WebkitTextFillColor: colors.black,
      borderRadius: '0.25rem',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.disabledGreyBorder,
  },
};

const inputErrorStyles: SystemStyleObject<Theme> = {
  color: 'error.main',
};

function FormikTextField({
  name,
  type,
  label,
  disabled,
  placeholder,
  autoComplete,
  fullWidth,
  value,
  inputMode,
  onChange,
  formControlStyles,
}: FormikTextFieldProps) {
  const [field, meta] = useField(name);
  const error: string | undefined = meta.error;
  const hasError: boolean = meta.touched && !!error;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    field?.onChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  const valueToDisplay: string | number | null = value || field.value;

  return (
    <FormControl
      variant="outlined"
      sx={{ ...baseFormControlStyles, ...formControlStyles }}
      fullWidth={fullWidth}
    >
      <InputLabel sx={inputLabelStyles} shrink>
        {label}
      </InputLabel>
      <TextField
        margin="none"
        variant="outlined"
        size="small"
        data-testid="input-element"
        error={hasError}
        sx={{ ...baseTextFieldStyles, paddingBlockStart: label ? '0.8rem' : 0 }}
        {...field}
        onChange={handleOnChange}
        disabled={disabled}
        type={type || 'text'}
        placeholder={placeholder}
        value={valueToDisplay}
        autoComplete={autoComplete}
        inputMode={inputMode}
        onWheel={(event) => {
          if (type === 'number' && event.target instanceof HTMLInputElement) {
            event.target.blur();
          }
        }}
        inputProps={{
          step: '0.01',
        }}
      />
      {hasError && (
        <FormHelperText sx={inputErrorStyles} variant="standard" data-testid="input-error-element">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormikTextField;
