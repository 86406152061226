import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import check from '../assets/check.svg';
import { Vertical } from '../types/verticals.type';
import { verticalHelper } from '../utils/vertical.helper';

interface VerticalBoxProps {
  vertical: Vertical | null;
  selected: boolean;
  handleClick: (vertical: Vertical) => void;
}

function VerticalBox({ vertical, selected, handleClick }: VerticalBoxProps) {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      className={`vertical-box ${selected ? 'selected' : ''}`}
      onClick={() => vertical && handleClick(vertical)}
    >
      <Box className="icon-container">{verticalHelper.getVerticalIcon(vertical?.verticalType, selected)}</Box>
      <Divider orientation="vertical" flexItem />
      <Stack direction="row" className="vertical-type-box">
        <Box className="vertical-info">
          <Typography className="vertical-title">
            {t(`verticals.${vertical!.verticalType.toLocaleLowerCase()}.title`)}
          </Typography>
          <Typography className="vertical-subtitle">
            {t(`verticals.${vertical!.verticalType.toLocaleLowerCase()}.subtitle`)}
          </Typography>
        </Box>
        {selected && <img src={check} alt="check" />}
      </Stack>
    </Stack>
  );
}

export default VerticalBox;
