import { useQuery } from '@tanstack/react-query';
import { verticalsService } from '../../services/verticals';
import { Vertical } from '../../types/verticals.type';
import queryKeys from '../../utils/query.helper';

function useVerticals() {
  return useQuery<Vertical[]>({
    queryFn: verticalsService.getVerticals,
    refetchOnWindowFocus: false,
    queryKey: queryKeys.verticals.base,
    staleTime: Infinity,
  });
}

export default useVerticals;
