import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { ReactComponent as PayPuntaOrangeLogo } from '../assets/logo-pp-orange.svg';
import Message from '../components/Message';
import { config } from '../config/config';
import { AppDispatch } from '../store/config';
import { useAppDispatch } from '../store/hooks';
import { error, success } from '../store/slices/payment.slice';
import { PaymentResponse } from '../types/paymentResult.types';

function PlexoRedirect() {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const beginPaymentId: string | null = searchParams.get('beginPaymentId');

  useEffect(() => {
    const serverUrl = config.backendUrl!.replace('/api/v1/', '');
    const socket = io(serverUrl);

    const connectFunction = () => {
      //TODO: Remove after backend allow redirect to local environement
      console.log('connect');
      socket.emit('handshake', beginPaymentId);
    };

    const successFunction = (paymentSuccess: PaymentResponse) => {
      //TODO: Remove after backend allow redirect to local environement
      console.log('Received event:', paymentSuccess);
      dispatch(success(paymentSuccess));
      navigate('/success');
    };

    const errorFunction = (paymentError: PaymentResponse) => {
      //TODO: Remove after backend allow redirect to local environement
      console.log('Received event:', paymentError);
      dispatch(error(paymentError));
      navigate('/error');
    };

    socket.on('connect', connectFunction);
    socket.on('paypunta:success', successFunction);
    socket.on('paypunta:error', errorFunction);

    return () => {
      socket.off('connect', connectFunction);
      socket.off('paypunta:success', successFunction);
      socket.off('paypunta:error', errorFunction);
    };
  }, [beginPaymentId, dispatch, navigate]);

  return (
    <Message
      id="plexo-redirect"
      payPuntaLogo={<PayPuntaOrangeLogo />}
      title={t('plexo-redirect.title')}
      text={t('plexo-redirect.text')}
      isLoading
    />
  );
}

export default PlexoRedirect;
