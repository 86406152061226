import colors from '../../styles/_variables.module.scss';
import { IconProps } from '../../types/icon.types';

function SuitcaseIcon({ selected }: IconProps) {
  const fillColor = selected ? colors.primary : colors.secondary;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42.344" height="34.405" viewBox="0 0 42.344 34.405">
      <g id="noun-case-5068078" transform="translate(0 -6)">
        <path
          id="Trazado_2877"
          data-name="Trazado 2877"
          d="M39.036,43.112H3.308A3.312,3.312,0,0,1,0,39.8v-22.5A3.312,3.312,0,0,1,3.308,14H39.036a3.312,3.312,0,0,1,3.308,3.308V39.8a3.312,3.312,0,0,1-3.308,3.308ZM3.308,15.323a1.987,1.987,0,0,0-1.985,1.985V39.8a1.987,1.987,0,0,0,1.985,1.985H39.036A1.987,1.987,0,0,0,41.021,39.8v-22.5a1.987,1.987,0,0,0-1.985-1.985Z"
          transform="translate(0 -2.707)"
          fill={fillColor}
        />
        <path
          id="Trazado_2878"
          data-name="Trazado 2878"
          d="M22.662,13.293A.661.661,0,0,1,22,12.631V8.662a.662.662,0,1,1,1.323,0v3.97a.661.661,0,0,1-.662.662Z"
          transform="translate(-7.444 -0.677)"
          fill={fillColor}
        />
        <path
          id="Trazado_2879"
          data-name="Trazado 2879"
          d="M22.662,8.647A.661.661,0,0,1,22,7.985,1.987,1.987,0,0,1,23.985,6a.662.662,0,0,1,0,1.323.662.662,0,0,0-.662.662.661.661,0,0,1-.662.662Z"
          transform="translate(-7.444)"
          fill={fillColor}
        />
        <path
          id="Trazado_2880"
          data-name="Trazado 2880"
          d="M40.662,13.293A.661.661,0,0,1,40,12.631V8.662a.662.662,0,1,1,1.323,0v3.97a.661.661,0,0,1-.662.662Z"
          transform="translate(-13.535 -0.677)"
          fill={fillColor}
        />
        <path
          id="Trazado_2881"
          data-name="Trazado 2881"
          d="M39.985,8.647a.661.661,0,0,1-.662-.662.662.662,0,0,0-.662-.662.662.662,0,1,1,0-1.323,1.987,1.987,0,0,1,1.985,1.985.661.661,0,0,1-.662.662Z"
          transform="translate(-12.858)"
          fill={fillColor}
        />
        <path
          id="Trazado_2882"
          data-name="Trazado 2882"
          d="M33.924,7.323H24.662a.662.662,0,0,1,0-1.323h9.263a.662.662,0,1,1,0,1.323Z"
          transform="translate(-8.121)"
          fill={fillColor}
        />
        <path
          id="Trazado_2883"
          data-name="Trazado 2883"
          d="M37.662,13.97A.661.661,0,0,1,37,13.308V10.662a.662.662,0,1,1,1.323,0v2.647a.661.661,0,0,1-.662.662Z"
          transform="translate(-12.52 -1.353)"
          fill={fillColor}
        />
        <path
          id="Trazado_2884"
          data-name="Trazado 2884"
          d="M25.662,13.97A.661.661,0,0,1,25,13.308V10.662a.662.662,0,1,1,1.323,0v2.647a.661.661,0,0,1-.662.662Z"
          transform="translate(-8.459 -1.353)"
          fill={fillColor}
        />
        <path
          id="Trazado_2885"
          data-name="Trazado 2885"
          d="M34.571,11.323H22.662a.662.662,0,0,1,0-1.323H34.571a.662.662,0,1,1,0,1.323Z"
          transform="translate(-7.444 -1.353)"
          fill={fillColor}
        />
        <path
          id="Trazado_2886"
          data-name="Trazado 2886"
          d="M6.662,14.308A.661.661,0,0,1,6,13.647V11.662a.662.662,0,0,1,1.323,0v1.985a.661.661,0,0,1-.662.662Z"
          transform="translate(-2.03 -1.692)"
          fill={fillColor}
        />
        <path
          id="Trazado_2887"
          data-name="Trazado 2887"
          d="M11.955,12.323H6.662a.662.662,0,1,1,0-1.323h5.293a.662.662,0,1,1,0,1.323Z"
          transform="translate(-2.03 -1.692)"
          fill={fillColor}
        />
        <path
          id="Trazado_2888"
          data-name="Trazado 2888"
          d="M14.662,14.308A.661.661,0,0,1,14,13.647V11.662a.662.662,0,0,1,1.323,0v1.985a.661.661,0,0,1-.662.662Z"
          transform="translate(-4.737 -1.692)"
          fill={fillColor}
        />
        <path
          id="Trazado_2889"
          data-name="Trazado 2889"
          d="M48.662,14.308A.661.661,0,0,1,48,13.647V11.662a.662.662,0,1,1,1.323,0v1.985a.661.661,0,0,1-.662.662Z"
          transform="translate(-16.242 -1.692)"
          fill={fillColor}
        />
        <path
          id="Trazado_2890"
          data-name="Trazado 2890"
          d="M53.955,12.323H48.662a.662.662,0,1,1,0-1.323h5.293a.662.662,0,0,1,0,1.323Z"
          transform="translate(-16.242 -1.692)"
          fill={fillColor}
        />
        <path
          id="Trazado_2891"
          data-name="Trazado 2891"
          d="M56.662,14.308A.661.661,0,0,1,56,13.647V11.662a.662.662,0,0,1,1.323,0v1.985a.661.661,0,0,1-.662.662Z"
          transform="translate(-18.949 -1.692)"
          fill={fillColor}
        />
        <path
          id="Trazado_2892"
          data-name="Trazado 2892"
          d="M8.662,43.112A.661.661,0,0,1,8,42.45V14.662a.662.662,0,1,1,1.323,0V42.45a.661.661,0,0,1-.662.662Z"
          transform="translate(-2.707 -2.707)"
          fill={fillColor}
        />
        <path
          id="Trazado_2893"
          data-name="Trazado 2893"
          d="M12.662,43.112A.661.661,0,0,1,12,42.45V14.662a.662.662,0,1,1,1.323,0V42.45a.661.661,0,0,1-.662.662Z"
          transform="translate(-4.06 -2.707)"
          fill={fillColor}
        />
        <path
          id="Trazado_2894"
          data-name="Trazado 2894"
          d="M50.662,43.112A.661.661,0,0,1,50,42.45V14.662a.662.662,0,1,1,1.323,0V42.45a.661.661,0,0,1-.662.662Z"
          transform="translate(-16.918 -2.707)"
          fill={fillColor}
        />
        <path
          id="Trazado_2895"
          data-name="Trazado 2895"
          d="M54.662,43.112A.661.661,0,0,1,54,42.45V14.662a.662.662,0,1,1,1.323,0V42.45a.661.661,0,0,1-.662.662Z"
          transform="translate(-18.272 -2.707)"
          fill={fillColor}
        />
        <path
          id="Trazado_2896"
          data-name="Trazado 2896"
          d="M26.918,40.855a9.936,9.936,0,1,1,9.936-9.936A9.936,9.936,0,0,1,26.918,40.855Zm0-18.549a8.613,8.613,0,1,0,8.613,8.613A8.613,8.613,0,0,0,26.918,22.306Z"
          transform="translate(-5.746 -5.07)"
          fill={fillColor}
        />
        <path
          id="Trazado_2897"
          data-name="Trazado 2897"
          d="M35.379,44.329h0l-15.223-.006a.662.662,0,1,1,0-1.323h0l15.223.006a.662.662,0,1,1,0,1.323Z"
          transform="translate(-6.596 -12.52)"
          fill={fillColor}
        />
        <path
          id="Trazado_2898"
          data-name="Trazado 2898"
          d="M24.68,41.655a.661.661,0,0,1-.662-.662A1.987,1.987,0,0,1,26,39.009a.662.662,0,0,1,0,1.323.662.662,0,0,0-.662.662.661.661,0,0,1-.662.662Z"
          transform="translate(-8.127 -11.169)"
          fill={fillColor}
        />
        <path
          id="Trazado_2899"
          data-name="Trazado 2899"
          d="M38,41.655a.661.661,0,0,1-.662-.662.662.662,0,0,0-.662-.662.662.662,0,1,1,0-1.323,1.987,1.987,0,0,1,1.985,1.985.661.661,0,0,1-.662.662Z"
          transform="translate(-12.188 -11.169)"
          fill={fillColor}
        />
        <path
          id="Trazado_2900"
          data-name="Trazado 2900"
          d="M33.3,40.332H26.68a.662.662,0,1,1,0-1.323H33.3a.662.662,0,1,1,0,1.323Z"
          transform="translate(-8.804 -11.169)"
          fill={fillColor}
        />
        <path
          id="Trazado_2901"
          data-name="Trazado 2901"
          d="M38.68,43.655a.661.661,0,0,1-.662-.662V41.67a.662.662,0,1,1,1.323,0v1.323a.661.661,0,0,1-.662.662Z"
          transform="translate(-12.864 -11.846)"
          fill={fillColor}
        />
        <path
          id="Trazado_2902"
          data-name="Trazado 2902"
          d="M24.68,43.655a.661.661,0,0,1-.662-.662V41.67a.662.662,0,1,1,1.323,0v1.323a.661.661,0,0,1-.662.662Z"
          transform="translate(-8.127 -11.846)"
          fill={fillColor}
        />
        <path
          id="Trazado_2903"
          data-name="Trazado 2903"
          d="M31.68,37.632a.661.661,0,0,1-.662-.662V31.692a.662.662,0,1,1,1.323,0v5.279a.661.661,0,0,1-.662.662Z"
          transform="translate(-10.496 -8.469)"
          fill={fillColor}
        />
        <path
          id="Trazado_2904"
          data-name="Trazado 2904"
          d="M36.973,31a.661.661,0,0,1-.662-.662,1.985,1.985,0,1,0-3.97,0,.662.662,0,1,1-1.323,0,3.308,3.308,0,1,1,6.616,0,.661.661,0,0,1-.662.662Z"
          transform="translate(-10.496 -7.116)"
          fill={fillColor}
        />
        <path
          id="Trazado_2905"
          data-name="Trazado 2905"
          d="M34.327,32.677a.661.661,0,0,1-.662-.662.662.662,0,1,0-1.323,0,.662.662,0,1,1-1.323,0,1.985,1.985,0,0,1,3.97,0,.661.661,0,0,1-.662.662Z"
          transform="translate(-10.496 -8.131)"
          fill={fillColor}
        />
        <path
          id="Trazado_2906"
          data-name="Trazado 2906"
          d="M28.973,31a.661.661,0,0,1-.662-.662,1.985,1.985,0,1,0-3.97,0,.662.662,0,0,1-1.323,0,3.308,3.308,0,1,1,6.616,0,.661.661,0,0,1-.662.662Z"
          transform="translate(-7.789 -7.116)"
          fill={fillColor}
        />
        <path
          id="Trazado_2907"
          data-name="Trazado 2907"
          d="M30.327,32.677a.661.661,0,0,1-.662-.662.662.662,0,0,0-1.323,0,.662.662,0,1,1-1.323,0,1.985,1.985,0,1,1,3.97,0,.661.661,0,0,1-.662.662Z"
          transform="translate(-9.142 -8.131)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default SuitcaseIcon;
