import { createSlice } from '@reduxjs/toolkit';

export interface UIstate {
  submitDisabled: boolean;
}

const initialState: UIstate = {
  submitDisabled: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSubmitDisabled: (state: UIstate, action) => {
      state.submitDisabled = action.payload;
    },
  },
});

const { reducer } = uiSlice;

export const { setSubmitDisabled } = uiSlice.actions;

export default reducer;
