import { Box, Typography } from '@mui/material';

interface DetailRowProps {
  customClass?: string;
  title: string;
  data: string | number | null;
}

function DetailRow({ customClass, title, data }: DetailRowProps) {
  return (
    <Box className={`row ${customClass}`}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2">{data}</Typography>
    </Box>
  );
}

export default DetailRow;
