import { Box } from '@mui/material';
import React from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import { RootState } from '../../../../store/config';
import { useAppSelector } from '../../../../store/hooks';
import { Commerce } from '../../../../types/commerce.type';
import { Vertical } from '../../../../types/verticals.type';
import { commerceHelper } from '../../../../utils/commerce.helper';
import DebtForm from './DebtForm';
import PaymentDetails from './PaymentDetails';

interface PaymentInformationProps {
  setInvoice: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  setTipPercentage: React.Dispatch<React.SetStateAction<number>>;
  currency: string;
  tipPercentage: number;
}

function PaymentInformationStep({
  setInvoice,
  setAmount,
  setTipPercentage,
  currency,
  tipPercentage,
  setCurrency,
}: PaymentInformationProps) {
  const { isMobile } = useWindowSize();
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerces: Commerce[] | undefined = vertical?.commerces;

  const restaurantId: number | undefined = commerceHelper.findCommerceIdByName(commerce?.name, commerces);

  return (
    <Box className="payment-information-step">
      <DebtForm
        setInvoice={setInvoice}
        setAmount={setAmount}
        setTipPercentage={setTipPercentage}
        setCurrency={setCurrency}
        restaurantId={restaurantId || commerce?.commerceId}
      />
      {!isMobile && (
        <div className="detail-column">
          <PaymentDetails
            tipPercentage={tipPercentage}
            currency={currency}
            restaurantId={restaurantId || commerce?.commerceId}
          />
        </div>
      )}
    </Box>
  );
}

export default PaymentInformationStep;
