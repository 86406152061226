import i18next, { Resource } from 'i18next';
import LenguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import portugueseTranslation from './locales/br/translations.json';
import englishTranslation from './locales/en/translations.json';
import spanishTranslation from './locales/es/translations.json';

const resources: Resource = {
  es: { translations: spanishTranslation },
  en: { translations: englishTranslation },
  br: { translations: portugueseTranslation },
};

i18next
  .use(initReactI18next)
  .use(LenguageDetector)
  .init({
    debug: false,
    fallbackLng: 'es',
    resources: resources,
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18next;
