import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { PIX_PAYMENT_METHOD_NAME } from '../data/constants';
import {
  ExpensesPaymentSuccess,
  HotelPaymentSuccess,
  RestaurantPaymentSuccess,
  ServicePaymentSuccess,
  TravelPaymentSuccess,
} from '../types/paymentResult.types';
import { amountHelper } from '../utils/amount.helper';
import { styles } from '../utils/receipt.helper';

interface CommonReceiptDataProps {
  data:
    | RestaurantPaymentSuccess
    | ExpensesPaymentSuccess
    | TravelPaymentSuccess
    | HotelPaymentSuccess
    | ServicePaymentSuccess;
  children?: JSX.Element;
}

function CommonReceiptData({ data, children }: CommonReceiptDataProps) {
  const { t } = useTranslation();
  return (
    <>
      <View style={styles.horizontalChunkBorder}>
        {data?.selectedPaymentMethod?.name === PIX_PAYMENT_METHOD_NAME ? (
          <>
            <Text style={styles.detailText}>{t('receipt-detail.payment-method')}</Text>
            <Text>{data?.selectedPaymentMethod?.name}</Text>
          </>
        ) : (
          <>
            <Text style={styles.detailText}>{data?.selectedPaymentMethod?.name}</Text>
            <View style={styles.cardTextRow}>
              <Text style={styles.smallText}>{t('receipt-detail.card-ending')}</Text>
              <Text>{data?.selectedPaymentMethod?.maskedPan}</Text>
            </View>
          </>
        )}
      </View>
      <View style={styles.horizontalChunkBorder}>
        <Text style={{ marginBottom: 12 }}>{t('receipt-detail.currency')}</Text>
        <Text style={{ marginBottom: 12 }}>
          {data?.currency && amountHelper.getCurrencyText(data?.currency)}
        </Text>
      </View>
      <View style={styles.horizontalChunk}>
        <Text>{t('receipt-detail.amount')}&nbsp;</Text>
        <Text>{amountHelper.getAmountFormatted(data?.amount)}</Text>
      </View>
      <View style={styles.horizontalChunk}>
        <Text>{t('receipt-detail.commission')}&nbsp;</Text>
        <Text>
          {data?.commissionAmount || data?.fixedCommissionAmount
            ? amountHelper.getAmountFormatted(data?.commissionAmount + data?.fixedCommissionAmount)
            : '0,00'}
        </Text>
      </View>
      {children}
      <View style={styles.totalRow}>
        <Text style={styles.totalText}>{t('receipt-detail.total')}&nbsp;</Text>
        <Text style={styles.totalText}>{amountHelper.getAmountFormatted(data?.totalAmount)}</Text>
      </View>
      <View style={styles.gap} />
    </>
  );
}

export default CommonReceiptData;
