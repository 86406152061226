import { Commerce } from '../types/commerce.type';

const findCommerceByIdentifier = (
  urlSafeNameToFind: string | null,
  commerceIdToFind: number | null,
  commerces: Commerce[] | undefined,
) => {
  const commerce = commerces?.find(
    (commerce) => commerce.commerceId === commerceIdToFind || commerce.urlSafeName === urlSafeNameToFind,
  );
  return commerce;
};

const findCommerceByName = (
  nameToFind: string | null,
  commerces: Commerce[] | undefined,
): Commerce | undefined => {
  const commerce = commerces?.find((commerce) => commerce.name === nameToFind);
  return commerce;
};

//TODO: Use findCommerceByName
const findCommerceIdByName = (nameToFind?: string, commerces?: Commerce[]): number | undefined => {
  const commerce = commerces?.find((commerce) => commerce.name === nameToFind);
  return commerce?.commerceId;
};

const sortVerticalCommerces = (commerces: Commerce[] | undefined) => {
  const sortedCommerces: string[] | undefined = commerces
    ?.filter((commerce) => commerce.enabled)
    .map((commerce) => commerce.name)
    .sort((a, b) => a.localeCompare(b));

  return sortedCommerces;
};

export const commerceHelper = {
  findCommerceByIdentifier,
  findCommerceByName,
  findCommerceIdByName,
  sortVerticalCommerces,
};
