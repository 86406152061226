import { FormikValues, useFormikContext } from 'formik';
import { BaseSyntheticEvent } from 'react';
import NumberFormat from 'react-number-format';
import FormikTextField from './FormikTextField';

interface FormikNumberFormatProps {
  name: string;
  label: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (e: BaseSyntheticEvent) => void;
}

const FormikNumberFormat = ({
  name,
  label,
  fullWidth = false,
  placeholder,
  disabled,
  onChange,
}: FormikNumberFormatProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const handleValueChange = (values: FormikValues) => {
    setFieldValue(name, values.floatValue);
  };

  return (
    <NumberFormat
      name={name}
      value={values[name]}
      type="tel"
      thousandSeparator="."
      decimalSeparator=","
      onValueChange={handleValueChange}
      customInput={FormikTextField}
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default FormikNumberFormat;
