import { FormControl, FormHelperText, InputLabel, MenuItem, TextField } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { useField } from 'formik';
import { ChangeEvent } from 'react';
import colors from '../../styles/_variables.module.scss';

export interface SelectOption {
  id: string;
  value: string | number;
}

export interface FormikSelectFieldProps {
  name: string;
  options: SelectOption[];
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  native?: boolean;
  fullWidth?: boolean;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isNumber?: boolean;
}

const formControlStyles: SystemStyleObject<Theme> = {
  margin: (theme: Theme) => `${theme.spacing(1)} 0`,
};

const inputLabelStyles: SystemStyleObject<Theme> = {
  '&.MuiFormLabel-root, &.MuiFormLabel-root.Mui-focused': {
    color: colors.lightGrey,
    left: '-10px',
    font: 'normal normal normal 16px Rubik',
  },
};

const selectFieldStyles: SystemStyleObject<Theme> = {
  backgroundColor: colors.white,
  color: colors.lightGrey,
  textAlign: 'left',
  font: 'normal normal normal 16px Rubik',
  '& .MuiOutlinedInput-root': {
    '& .Mui-disabled': {
      backgroundColor: colors.disabledGrey,
      WebkitTextFillColor: colors.black,
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.disabledGreyBorder,
  },
};

const inputErrorStyles: SystemStyleObject<Theme> = {
  color: 'error.main',
};

function FormikSelectField({
  name,
  label,
  options,
  disabled,
  placeholder,
  native,
  fullWidth,
  value,
  onChange,
  isNumber,
}: FormikSelectFieldProps) {
  const [field, meta, helpers] = useField(name);
  const error: string | undefined = meta.error;
  const hasError: boolean = meta.touched && !!error;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(!isNumber ? event.target.value : Number(event.target.value));
  };

  const selectedValue = value || field?.value;
  const noValueSelected = !selectedValue || selectedValue === placeholder;

  return (
    <FormControl variant="outlined" sx={formControlStyles} fullWidth={fullWidth}>
      <InputLabel sx={inputLabelStyles} shrink>
        {label}
      </InputLabel>
      <TextField
        margin="none"
        variant="outlined"
        size="small"
        data-testid="input-element"
        error={hasError}
        sx={{
          ...selectFieldStyles,
          paddingBlockStart: label ? '0.8rem' : 0,
          ...(noValueSelected ? { '[role="button"]': { color: colors.placeholderGrey } } : {}),
        }}
        {...field}
        onChange={onChange || handleOnChange}
        disabled={disabled}
        SelectProps={{ displayEmpty: !!placeholder, native, title: 'select-element' }}
        select
        value={value || field?.value}
      >
        {placeholder &&
          (native ? (
            <option disabled value="">
              {placeholder}
            </option>
          ) : (
            <MenuItem value={placeholder}>{placeholder}</MenuItem>
          ))}
        {options.map((option: SelectOption) => {
          if (native) {
            return (
              <option value={option.id} key={option.id}>
                {option.value}
              </option>
            );
          }

          return (
            <MenuItem value={option.id} key={option.id}>
              {option.value}
            </MenuItem>
          );
        })}
      </TextField>
      {hasError && (
        <FormHelperText sx={inputErrorStyles} variant="standard" data-testid="input-error-element">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormikSelectField;
