import { t } from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoginLayout from '../components/Login/LoginLayout';
import ExpensesLoginForm from '../components/Verticals/Expenses/LoginForm';
import HotelsLoginForm from '../components/Verticals/Hotels/LoginForm';
import RestaurantsLoginForm from '../components/Verticals/Restaurants/LoginForm';
import ServicesLoginForm from '../components/Verticals/Services/LoginForm';
import TravelsLoginForm from '../components/Verticals/Travels/LoginForm';
import { RootState } from '../store/config';
import { useAppSelector } from '../store/hooks';
import { resetCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep } from '../store/slices/step.slice';
import { VerticalType } from '../types/enums';
import { Vertical } from '../types/verticals.type';

function Login() {
  const dispatch = useDispatch();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const params = useParams();
  const isPaymentLink = !!params.commerceIdentifier?.toLowerCase();

  useEffect(() => {
    if (!isPaymentLink) {
      dispatch(resetCommerce());
      dispatch(resetDebtState());
      dispatch(resetStep());
    }
  }, [dispatch, isPaymentLink]);

  switch (vertical?.verticalType) {
    case VerticalType.EXPENSES:
      return (
        <LoginLayout
          formTitle={t('verticals.expenses.login-title')}
          form={<ExpensesLoginForm />}
          contentClass="expenses-content"
          formClass="expenses-form"
        />
      );
    case VerticalType.HOTELS:
      return (
        <LoginLayout
          formTitle={t('verticals.hotels.login-title')}
          form={<HotelsLoginForm />}
          contentClass="hotels-content"
          formClass="hotels-form"
        />
      );
    case VerticalType.SERVICES:
      return (
        <LoginLayout
          formTitle={t('verticals.services.login-title')}
          form={<ServicesLoginForm />}
          contentClass="services-content"
          formClass="services-form"
        />
      );
    case VerticalType.TRAVEL_AGENCY:
      return (
        <LoginLayout
          formTitle={t('verticals.travel_agency.login-title')}
          form={<TravelsLoginForm />}
          contentClass="travels-content"
          formClass="travels-form"
        />
      );
    case VerticalType.RESTAURANTS:
      return (
        <LoginLayout
          formTitle={t('verticals.restaurants.login-title')}
          form={<RestaurantsLoginForm />}
          formClass="restaurants-form"
        />
      );
    default:
      return <p>{t('errors.missing-commerce')}</p>;
  }
}

export default Login;
