export const stepsLabels = [
  'verticals.step-labels.payment-information',
  'verticals.step-labels.payment-method',
  'verticals.step-labels.confirmation',
];

export const restaurantsStepsLabels = [
  'verticals.restaurants.payment-steps.info',
  'verticals.restaurants.payment-steps.payment-method',
  'verticals.restaurants.payment-steps.email',
  'verticals.restaurants.payment-steps.confirmation',
];

export const paymentLinkStepsLabels = [
  'payment-link-steps-labels.payment-info',
  'payment-link-steps-labels.payment-method',
  'payment-link-steps-labels.email',
  'payment-link-steps-labels.confirmation',
];
