import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/_variables.module.scss';
import { ExpensesPaymentSuccess, PaymentResponse } from '../../../types/paymentResult.types';
import { formatCustomDate, styles } from '../../../utils/receipt.helper';
import CommonReceiptData from '../../CommonReceiptData';

interface ExpensesReceiptDetailProps {
  paymentSuccess: PaymentResponse | null;
}

function ExpensesReceiptDetail({ paymentSuccess }: ExpensesReceiptDetailProps) {
  const { t } = useTranslation();
  const data = paymentSuccess?.data as ExpensesPaymentSuccess;

  return (
    <>
      <View style={styles.detailHeader}>
        <Text style={styles.headerTitle}>{t('receipt-detail.title')}</Text>
        <Text style={styles.headerSubTitle}>{data?.building?.toUpperCase()}</Text>
        <Text style={styles.text}>{t('verticals.expenses.receipt-detail.subtitle')}</Text>
        <View style={styles.gap} />
        <Text style={styles.text}>{data?.paymentId}</Text>
        <Text style={styles.text}>{`Unidad ${data?.apartment}`}</Text>
        <Text style={{ color: colors.placeholderGrey, fontSize: 12 }}>
          {formatCustomDate(data?.date || '')}
        </Text>
      </View>
      <View style={styles.gap} />
      <CommonReceiptData data={data} children={<View style={styles.gap} />} />
    </>
  );
}

export default ExpensesReceiptDetail;
