import { AnchorHTMLAttributes, useRef } from 'react';
import variables from '../../styles/_variables.module.scss';
import RotateText, { RotateTextProps } from './RotateText';

interface RotateButtonProps extends RotateTextProps {
  icon: JSX.Element;
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  color?: 'primary' | 'accent' | 'white';
  size?: number;
  isSuccess?: boolean;
}

interface cleanCanvasContextProps {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
}

function RotateButton({
  icon,
  text,
  curvyTextStyle,
  href = '#',
  rotate = false,
  color = 'primary',
  size = 94,
  isSuccess,
}: RotateButtonProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const hoverCircle = (e: any) => {
    const canvasHoverColor: string = isSuccess
      ? variables.rotateButtonHoverSecondary
      : variables.rotateButtonHoverPrimary;
    const { canvas, ctx }: cleanCanvasContextProps = cleanCanvasContext();
    const canvasRect: DOMRect = canvas.getBoundingClientRect();
    const x: number = e.clientX - canvasRect.left;
    const y: number = e.clientY - canvasRect.top;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.arc(x, y, 18, 0, Math.PI * 2);
    ctx.fillStyle = canvasHoverColor;
    ctx.fill();
  };

  const cleanCanvasContext = () => {
    const canvas: HTMLCanvasElement = canvasRef.current!;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    return { ctx, canvas };
  };

  return (
    <a
      href={href}
      className="button"
      onMouseMove={hoverCircle}
      onMouseLeave={cleanCanvasContext}
      style={{ width: size, height: size }}
      aria-label={text}
    >
      <canvas ref={canvasRef} height={size} width={size} />
      {icon}
      <RotateText text={text} rotate={rotate} curvyTextStyle={curvyTextStyle} />
    </a>
  );
}

export default RotateButton;
