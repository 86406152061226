import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import useWindowSize from '../../../hooks/useWindowSize';
import { RootState } from '../../../store/config';
import { useAppSelector } from '../../../store/hooks';
import { HotelsDebt } from '../../../types/debt.types';
import { Issuer } from '../../../types/paymentMethod.types';
import { amountHelper } from '../../../utils/amount.helper';
import DetailRow from '../../DetailRow';

function PaymentDetails() {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { fixedCommission, debtWithFeeFormattedCurrency, debtFormattedCurrency } = usePaymentStepper();
  const debt: HotelsDebt = useAppSelector((state: RootState) => state.debt.hotelsDebt);
  const issuer: Issuer | null = useAppSelector((state: RootState) => state.issuer.issuer);

  return (
    <Box className="details-container">
      <Box className="details">
        <Box className="row">{!isMobile && <h6>{t('verticals.hotels.payment-detail.title')}</h6>}</Box>
        <DetailRow title={t('verticals.hotels.payment-detail.data.hotelName')} data={debt.hotelName} />
        <DetailRow
          title={t('verticals.hotels.payment-detail.data.currency')}
          data={amountHelper.getCurrencyText(debt.currency)}
        />
        <DetailRow
          title={t('verticals.hotels.payment-detail.data.debt')}
          data={
            debt.amount &&
            amountHelper.getAmountFormattedWithCurrency(debt.currency, debt.amount && +debt.amount)
          }
        />
        {(issuer?.commission || fixedCommission) && (
          <DetailRow
            title={t('verticals.hotels.payment-detail.data.commission')}
            data={
              issuer
                ? amountHelper.getCommissionText(
                    issuer?.commission,
                    fixedCommission,
                    debt.currency,
                    issuer?.plexoIssuerId,
                  )
                : '-'
            }
            customClass="commission"
          />
        )}
        <Divider />
        <Box className="row total">
          {t('verticals.hotels.payment-detail.data.total')}
          <span>{issuer ? debtWithFeeFormattedCurrency : debtFormattedCurrency}</span>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentDetails;
