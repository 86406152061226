import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/user.types';

const expirationTime = 2 * 60 * 60 * 1000;

const initialState: User = {
  userName: null,
  email: null,
  phone: null,
  documentType: null,
  document: null,
  timeout: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: User, action: PayloadAction<User>) => {
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.documentType = action.payload.documentType;
      state.document = action.payload.document;
      state.timeout = new Date().getTime() + expirationTime;
    },
    resetUser: () => initialState,
  },
});

const { reducer } = userSlice;

export const { setUser, resetUser } = userSlice.actions;

export default reducer;
