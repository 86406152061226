import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Commerce } from '../../types/commerce.type';

export interface CommerceState {
  commerce: Commerce | null;
}

const initialState: CommerceState = {
  commerce: null,
};

const commerceSlice = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    setCommerce: (state: CommerceState, action: PayloadAction<Commerce>) => {
      state.commerce = action.payload;
    },
    resetCommerce: () => initialState,
  },
});

const { reducer } = commerceSlice;

export const { resetCommerce, setCommerce } = commerceSlice.actions;

export default reducer;
