import axios from './axios';

const getVerticals = async () => {
  try {
    const res = await axios.get('/verticals');
    return res.data.data;
  } catch (err: any) {
    throw err;
  }
};

export const verticalsService = { getVerticals };
