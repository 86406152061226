import { createSlice } from '@reduxjs/toolkit';
import { AmountDetail } from '../../types/amount.types';

export interface AmountState {
  amount: AmountDetail | null;
  loading: boolean;
  errorMessage: string | null;
}

const initialState: AmountState = {
  amount: null,
  loading: false,
  errorMessage: null,
};

const amountSlice = createSlice({
  name: 'amount',
  initialState,
  reducers: {
    setAmountState: (state: AmountState, action) => {
      state.amount = action.payload;
      state.loading = false;
      state.errorMessage = null;
    },
    setAmountStateLoading: (state: AmountState) => {
      state.loading = true;
    },
    setAmountStateError: (state: AmountState, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
  },
});

const { reducer } = amountSlice;

export const { setAmountState, setAmountStateLoading, setAmountStateError } = amountSlice.actions;

export default reducer;
