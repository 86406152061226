import { AnyAction, Reducer, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import amountSlice from './slices/amount.slice';
import commerceSlice from './slices/commerce.slice';
import debtSlice from './slices/debt.slice';
import issuerSlice from './slices/issuer.slice';
import paymentSlice from './slices/payment.slice';
import stepSlice from './slices/step.slice';
import uiSlice from './slices/ui.slice';
import userReducer from './slices/user.slice';
import verticalSlice from './slices/vertical.slice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (
      action.type === 'user/setUser' ||
      action.type === 'user/resetUser' ||
      action.type === 'ui/setSubmitDisabled'
    ) {
      return null;
    }
    return action;
  },
});

const createPersistedReducer = <T>(key: string, reducer: Reducer<T, AnyAction>) => {
  return persistReducer({ key, storage }, reducer);
};

const persistedStepReducer = createPersistedReducer('step', stepSlice);
const persistedDebtReducer = createPersistedReducer('debt', debtSlice);
const persistedUserReducer = createPersistedReducer('user', userReducer);
const persistedVerticalReducer = createPersistedReducer('vertical', verticalSlice);
const persistedCommerceReducer = createPersistedReducer('commerce', commerceSlice);

export const store = configureStore({
  reducer: {
    step: persistedStepReducer,
    user: persistedUserReducer,
    debt: persistedDebtReducer,
    payment: paymentSlice,
    vertical: persistedVerticalReducer,
    amount: amountSlice,
    commerce: persistedCommerceReducer,
    issuer: issuerSlice,
    ui: uiSlice,
  },
  enhancers: (defaultEnhancers) => [sentryReduxEnhancer, ...defaultEnhancers],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
