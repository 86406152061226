import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StepState {
  step: number;
}

const initialState: StepState = {
  step: 0,
};

const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    setStep: (state: StepState, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    resetStep: () => initialState,
  },
});

const { reducer } = stepSlice;

export const { setStep, resetStep } = stepSlice.actions;

export default reducer;
