/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PayPuntaOrangeLogo } from '../../assets/logo-pp-orange.svg';
import Message from '../../components/Message';
import { config } from '../../config/config';
import useVerticals from '../../hooks/queries/useVerticals';

function MaintenanceScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: verticals } = useVerticals();
  const text = (
    <Trans
      i18nKey="maintenance-screen.text"
      values={{ url: config.paypuntaLandingContactUrl }}
      components={{ a: <a href={config.paypuntaLandingContactUrl} /> }}
    />
  );

  useEffect(() => {
    if (verticals?.length === 0) {
      navigate('/');
    }
  }, [navigate, verticals]);

  return (
    <Message
      id="maintenance-page"
      payPuntaLogo={<PayPuntaOrangeLogo />}
      title={t('maintenance-screen.title')}
      text={text}
      isMaintenancePage={true}
    />
  );
}

export default MaintenanceScreen;
