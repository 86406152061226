import { Box } from '@mui/material';
import usePaymentMethods from '../../hooks/queries/usePaymentMethods';
import useWindowSize from '../../hooks/useWindowSize';
import { RootState } from '../../store/config';
import { useAppSelector } from '../../store/hooks';
import { Commerce } from '../../types/commerce.type';
import { Vertical } from '../../types/verticals.type';
import Loading from '../Animations/Loading';
import PaymentMethodsList from './PaymentMethodsList';

interface PaymentMethodsStepProps {
  children: JSX.Element;
}

function PaymentMethodsStep({ children }: PaymentMethodsStepProps) {
  const { isMobile } = useWindowSize();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const { data: paymentMethods, isLoading } = usePaymentMethods(vertical?.verticalType, commerce?.commerceId);

  if (isLoading) return <Loading centered />;

  return (
    <>
      {isMobile && [0, 1, undefined].includes(paymentMethods?.issuers?.length) ? (
        <Box className="single-payment-method-step">
          <PaymentMethodsList />
          {children}
        </Box>
      ) : (
        <Box className="payment-methods-step">
          {children}
          <PaymentMethodsList />
        </Box>
      )}
    </>
  );
}

export default PaymentMethodsStep;
