import { ONLY_DOLLARS_AND_TEN_PERCENT_TIP_RESTAURANTS } from '../data/constants';
import { currencies } from '../data/currencies';
import { tipPercentages } from '../data/tipPercentages';

export const getCurrencyAndTips = (restaurantId?: number) => {
  const restrictedRestaurant = ONLY_DOLLARS_AND_TEN_PERCENT_TIP_RESTAURANTS.find(
    (restaurant) => restaurant.restaurantId === restaurantId,
  );

  return {
    currencies: restrictedRestaurant
      ? currencies.filter((currency) => currency.id === restrictedRestaurant.currency)
      : currencies,
    tipPercentages: restrictedRestaurant
      ? tipPercentages.filter((tip) => tip.value === restrictedRestaurant.tipPercentage)
      : tipPercentages,
    restrictedRestaurant,
  };
};
