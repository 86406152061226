import React from 'react';
import ReactDOM from 'react-dom';
import '../src/i18n/config';
import App from './App';
import { config } from './config/config';
import reportWebVitals from './reportWebVitals';
import { configureSentry } from './utils/sentry';

if (['prod', 'qa'].includes(config.env || '')) {
  configureSentry();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
