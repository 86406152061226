import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../store/config';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { resetCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep, setStep } from '../store/slices/step.slice';
import { setSubmitDisabled } from '../store/slices/ui.slice';
import { Commerce } from '../types/commerce.type';
import { PaymentStep } from '../types/enums';
import { Issuer } from '../types/paymentMethod.types';
import { User } from '../types/user.types';
import { Vertical } from '../types/verticals.type';
import { amountHelper } from '../utils/amount.helper';
import { verticalHelper } from '../utils/vertical.helper';
import usePaymentMethods from './queries/usePaymentMethods';

export default function usePaymentStepper() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const step: number | null = useAppSelector((state: RootState) => state.step.step);
  const { submitDisabled: submitDisabledState } = useAppSelector((state: RootState) => state.ui);
  const user: User = useAppSelector((state: RootState) => state.user);
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const selectedIssuer: Issuer | null = useAppSelector((state: RootState) => state.issuer.issuer);
  const globalState = useAppSelector((state: RootState) => state);
  const debtState =
    vertical?.verticalType && verticalHelper.getVerticalDebt(vertical?.verticalType, globalState)?.debtState;

  const { data: paymentMethods } = usePaymentMethods(vertical?.verticalType, commerce?.commerceId);

  const isSubmitDisabled =
    submitDisabledState ||
    (step === PaymentStep.PAYMENT_METHODS && (!selectedIssuer || paymentMethods?.issuers.length === 0));

  const commerceIdentifierParam = params.commerceIdentifier?.toLowerCase();
  const isPaymentLink = !!commerceIdentifierParam;

  const debtFormattedCurrency = debtState?.amount
    ? amountHelper.getAmountFormattedWithCurrency(debtState.currency, debtState.amount)
    : '0';

  const fixedCommission: number | null =
    selectedIssuer?.fixedCommissionUyu || selectedIssuer?.fixedCommissionUsd
      ? debtState?.currency === 'UYU'
        ? selectedIssuer?.fixedCommissionUyu
        : selectedIssuer?.fixedCommissionUsd
      : null;

  const debtWithFee =
    debtState && (fixedCommission || selectedIssuer?.commission)
      ? amountHelper.getDebtWithCommission(debtState?.amount, fixedCommission, selectedIssuer?.commission)
      : debtState?.amount;

  const debtWithFeeFormattedCurrency =
    debtState && debtWithFee
      ? amountHelper.getAmountFormattedWithCurrency(debtState.currency, debtWithFee)
      : '';

  const handleGoBack = () => {
    if (step === PaymentStep.INFORMATION && user.userName) {
      navigate(`/${vertical?.verticalType.toLowerCase()}/login`, { state: { editUserInformation: true } });
      dispatch(resetCommerce());
      dispatch(resetDebtState());
      dispatch(resetStep());
    } else if (step) {
      dispatch(setStep(step - 1));
    }
  };

  const updateSubmitDisabled = (disabled: boolean) => {
    dispatch(setSubmitDisabled(disabled));
  };

  return {
    vertical,
    step,
    setStep,
    isPaymentLink,
    commerceIdentifierParam,
    fixedCommission,
    debtFormattedCurrency,
    debtWithFeeFormattedCurrency,
    handleGoBack,
    isSubmitDisabled,
    updateSubmitDisabled,
  };
}
