import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { restaurantsStepsLabels } from '../../../data/paymentSteps';
import useVerticals from '../../../hooks/queries/useVerticals';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import useRestaurantStepper from '../../../hooks/useRestaurantStepper';
import { RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setVertical } from '../../../store/slices/vertical.slice';
import { Commerce } from '../../../types/commerce.type';
import { RestaurantsDebt } from '../../../types/debt.types';
import { PaymentStep, VerticalType } from '../../../types/enums';
import { Vertical } from '../../../types/verticals.type';
import CustomSnackbar from '../../Snackbar/CustomSnackbar';
import CustomStepper from '../../Stepper/CustomStepper';
import EmailStep from '../../Stepper/EmailStep';
import PaymentInformationStep from './PaymentInformation/PaymentInformationStep';
import PaymentMethodsStep from './PaymentMethods/PaymentMethodsStep';
import RestaurantsHeader from './RestaurantsHeader';

function PaymentStepper() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const debtState: RestaurantsDebt = useAppSelector((state: RootState) => state.debt.restaurantsDebt);
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const [amount, setAmount] = useState<number>(debtState.amount || 0);
  const [invoice, setInvoice] = useState<string>(debtState.invoice || '');
  const [tipPercentage, setTipPercentage] = useState<number>(debtState.tipPercentage || 0);
  const [currency, setCurrency] = useState<string>(debtState.currency || '');
  const { step } = usePaymentStepper();
  const { commerceIdentifierParam, isPaymentLink } = usePaymentStepper();

  const { data: verticals } = useVerticals();

  const { snackbarOpen, errorMessage, closeSnackbar } = useRestaurantStepper();

  useEffect(() => {
    const restaurantVertical: Vertical | undefined = verticals?.find(
      (vertical: Vertical) => vertical.verticalType === VerticalType.RESTAURANTS,
    );
    if (restaurantVertical) dispatch(setVertical(restaurantVertical));
  }, [dispatch, vertical, verticals]);

  if (!commerce?.restaurantEmployee)
    navigate(
      `/${VerticalType.RESTAURANTS.toLowerCase()}${isPaymentLink ? `/${commerceIdentifierParam}` : ''}/login`,
    );

  return (
    <>
      <RestaurantsHeader amount={amount} invoice={invoice} />
      <Box className="screen stepper-paper">
        <CustomStepper activeStep={step} stepLabels={restaurantsStepsLabels} />
        <Box id="restaurants">
          {step === PaymentStep.INFORMATION && (
            <PaymentInformationStep
              setInvoice={setInvoice}
              setAmount={setAmount}
              setCurrency={setCurrency}
              setTipPercentage={setTipPercentage}
              currency={currency}
              tipPercentage={tipPercentage}
            />
          )}
          {step === PaymentStep.PAYMENT_METHODS && <PaymentMethodsStep />}
          {step === PaymentStep.EMAIL && <EmailStep />}
        </Box>
      </Box>
      <CustomSnackbar
        isOpen={snackbarOpen}
        handleClose={closeSnackbar}
        severity="error"
        message={errorMessage}
      />
    </>
  );
}

export default PaymentStepper;
