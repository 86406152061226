import { Box, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/config';
import { useAppSelector } from '../../store/hooks';
import { User } from '../../types/user.types';

function UserInformationTable() {
  const { t } = useTranslation();
  const user: User = useAppSelector((state: RootState) => state.user);

  return (
    <Box className="user-information-table">
      <TableContainer id="table-container">
        <h6 className="subtitle">{t('user-information.subtitle')}</h6>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('user-information.username')}</TableCell>
              <TableCell>{t('user-information.email')}</TableCell>
              <TableCell>{t('user-information.document')}</TableCell>
              <TableCell>{t('user-information.phone')}</TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell>{user.userName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              {user.documentType}: {user.document}
            </TableCell>
            <TableCell>{user.phone}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default UserInformationTable;
