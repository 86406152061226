import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Issuer } from '../../types/paymentMethod.types';

interface IssuerState {
  issuer: Issuer | null;
}

const initialState: IssuerState = {
  issuer: null,
};

const issuerSlice = createSlice({
  name: 'issuer',
  initialState,
  reducers: {
    setIssuer: (state: IssuerState, action: PayloadAction<Issuer>) => {
      state.issuer = action.payload;
    },
  },
});

const { reducer } = issuerSlice;

export const { setIssuer } = issuerSlice.actions;

export default reducer;
