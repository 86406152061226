import clsx from 'clsx';
import type { SVGAttributes } from 'react';

export interface RotateTextProps {
  text: string;
  curvyTextStyle?: SVGAttributes<SVGSVGElement>['style'];
  rotate?: boolean;
}

function RotateText({ text, curvyTextStyle, rotate }: RotateTextProps) {
  return (
    <svg viewBox="0 0 500 500" className={clsx('curvy-text', rotate && 'rotate')} style={curvyTextStyle}>
      <defs>
        <path
          id="textcircle"
          d="M243.2, 382.4c-74.8, 0-135.5-60.7-135.5-135.5s60.7-135.5,135.5-135.5s135.5, 60.7, 135.5, 135.5 S318, 382.4, 243.2, 382.4z"
        />
      </defs>
      <text fill="currentColor">
        <textPath xlinkHref="#textcircle">{text}</textPath>
      </text>
    </svg>
  );
}

export default RotateText;
