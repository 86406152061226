export const config = {
  env: process.env.REACT_APP_ENV_NAME,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  publicUrl: process.env.PUBLIC_URL,
  captchaSiteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  buildingsUrl: process.env.REACT_APP_BUILDINGS_URL,
  restaurantsUrl: process.env.REACT_APP_RESTAURANTS_URL,
  trackingId: process.env.REACT_APP_TRACKING_ID,
  paypuntaLandingContactUrl: process.env.REACT_APP_PAYPUNTA_LANDING_CONTACT_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
};
