import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PIX_PLEXO_ISSUER_ID } from '../../../../data/constants';
import { restaurantsStepsLabels } from '../../../../data/paymentSteps';
import usePaymentMethods from '../../../../hooks/queries/usePaymentMethods';
import usePaymentStepper from '../../../../hooks/usePaymentStepper';
import { RootState } from '../../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setIssuer } from '../../../../store/slices/issuer.slice';
import { setStep } from '../../../../store/slices/step.slice';
import { Commerce } from '../../../../types/commerce.type';
import { PaymentStep, VerticalType } from '../../../../types/enums';
import { Issuer } from '../../../../types/paymentMethod.types';
import Loading from '../../../Animations/Loading';
import SinglePaymentMethod from '../../../SinglePaymentMethod';
import PaymentMethodsData from './PaymentMethodsData';

const PaymentMethodsStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { step } = usePaymentStepper();
  const {
    amount,
    loading: isLoadingAmount,
    errorMessage: amountError,
  } = useAppSelector((state: RootState) => state.amount);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const { data: paymentMethods } = usePaymentMethods(VerticalType.RESTAURANTS, commerce?.commerceId);

  const pixIssuer: Issuer | undefined = paymentMethods?.issuers.find(
    (issuer) => issuer.plexoIssuerId === PIX_PLEXO_ISSUER_ID || paymentMethods?.issuers[0],
  );

  useEffect(() => {
    if (pixIssuer) dispatch(setIssuer(pixIssuer));
  }, [dispatch, paymentMethods?.issuers, pixIssuer]);

  useEffect(() => {
    if (!amount) {
      dispatch(setStep(PaymentStep.INFORMATION));
    }
  }, [dispatch, amount]);

  if (isLoadingAmount) return <Loading centered />;

  return (
    <Box className="payment-methods-step">
      <Box className="details charge-detail">
        <h6>{t(restaurantsStepsLabels[step])}</h6>
        <PaymentMethodsData />
        {amountError && <p>* Error al obtener el total</p>}
      </Box>
      <Box className="commission-detail">
        <SinglePaymentMethod issuer={pixIssuer} />
      </Box>
    </Box>
  );
};

export default PaymentMethodsStep;
