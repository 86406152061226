import { Document, Image, Page, View } from '@react-pdf/renderer';
import PaypuntaLogo from '../assets/pay-punta.png';
import PlexoLogo from '../assets/plexo-logo.png';
import { VerticalType } from '../types/enums';
import { PaymentResponse } from '../types/paymentResult.types';
import { styles } from '../utils/receipt.helper';
import ExpensesReceiptDetail from './Verticals/Expenses/ExpensesReceiptDetail';
import HotelReceiptDetail from './Verticals/Hotels/HotelReceiptDetail';
import RestaurantReceiptDetail from './Verticals/Restaurants/RestaurantsReceiptDetail';
import ServiceReceiptDetail from './Verticals/Services/ServiceReceiptDetail';
import TravelReceiptDetail from './Verticals/Travels/TravelReceiptDetail';

interface PDFReceiptProps {
  paymentSuccess: PaymentResponse | null;
}

function PDFReceipt({ paymentSuccess }: PDFReceiptProps) {
  const renderVerticalReceiptDetail = () => {
    switch (paymentSuccess?.verticalType) {
      case VerticalType.EXPENSES:
        return <ExpensesReceiptDetail paymentSuccess={paymentSuccess} />;
      case VerticalType.HOTELS:
        return <HotelReceiptDetail paymentSuccess={paymentSuccess} />;
      case VerticalType.SERVICES:
        return <ServiceReceiptDetail paymentSuccess={paymentSuccess} />;
      case VerticalType.TRAVEL_AGENCY:
        return <TravelReceiptDetail paymentSuccess={paymentSuccess} />;
      case VerticalType.RESTAURANTS:
        return <RestaurantReceiptDetail paymentSuccess={paymentSuccess} />;
    }
  };
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={styles.content}>
              <View style={styles.receiptContainer}>
                <View style={styles.imageContainer}>
                  <Image style={styles.image} src={PaypuntaLogo} />
                </View>
                {renderVerticalReceiptDetail()}
                <View style={styles.footerContainer}>
                  <Image style={styles.footerImage} src={PlexoLogo} />
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

export default PDFReceipt;
