import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { paymentService } from '../services/payment';
import { mutationKeys } from '../types/enums';
import usePaymentStepper from './usePaymentStepper';

function useBeginPay() {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { updateSubmitDisabled } = usePaymentStepper();

  const openSnackbar = useCallback((message: string) => {
    setErrorMessage(message);
    setSnackbarOpen(true);
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarOpen(false);
    setErrorMessage('');
  }, []);

  const mutation = useMutation({
    mutationFn: paymentService.beginPay,
    mutationKey: mutationKeys.beginPay,

    onSuccess: (result) => {
      if (result.error) {
        openSnackbar(result.error);
      } else {
        window.location.href = result;
      }
    },
    onError: (error: Error) => {
      openSnackbar(error.message);
    },
    onMutate: () => {
      updateSubmitDisabled(true);
    },
    onSettled: () => {
      updateSubmitDisabled(false);
    },
  });

  return {
    submitBeginPay: mutation.mutate,
    isSubmitting: mutation.isLoading,
    snackbarOpen,
    errorMessage,
    closeSnackbar,
  };
}

export default useBeginPay;
