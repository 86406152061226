import colors from '../../styles/_variables.module.scss';
import { IconProps } from '../../types/icon.types';

function BuildingIcon({ selected }: IconProps) {
  const fillColor = selected ? colors.primary : colors.secondary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Grupo_3884"
      data-name="Grupo 3884"
      width="27.144"
      height="37.861"
      viewBox="0 0 27.144 37.861"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_1435"
            data-name="Rectángulo 1435"
            width="27.144"
            height="37.861"
            fill={fillColor}
          />
        </clipPath>
      </defs>
      <g id="Grupo_3883" data-name="Grupo 3883" clipPath="url(#clip-path)">
        <path
          id="Trazado_2876"
          data-name="Trazado 2876"
          d="M25.259,36.584v-2.5h0V31.57h0V22.71h0V20.2h0V18.929h0v-3.35h0V13.933a.9.9,0,0,1-.035-.314c.035-.9.013-1.8.01-2.7,0-.552-.124-.709-.662-.863q-2.608-.744-5.216-1.487c-.978-.279-1.955-.561-2.946-.847V7.292q0-3.2,0-6.395c0-.7-.2-.9-.908-.9H3.38c-.646,0-.856.216-.859.871,0,.1,0,.2,0,.3v35.44H0v1.254H27.144V36.584Zm-10.132-.012H3.808V35.351H9.44V34.085H3.805V31.558H9.443V30.3H3.8v-2.52H9.436V26.507H3.806V23.98H9.443V22.711H3.8V20.2H9.446V18.94H3.8V16.422H9.446V15.169H3.812V12.642H9.435V11.368H3.807V8.84H9.443V7.577H3.8V5.063H9.436V3.793H3.808V1.282H15.128Zm8.846-.309c0,.1-.01.194-.016.314H16.429V35.341h4.377v-1.25H16.435V31.564h4.36V30.295H16.429V27.772h4.4c0-.4.018-.753-.016-1.1-.006-.066-.209-.164-.323-.165-1.208-.012-2.415-.007-3.623-.007h-.427V23.973h4.37V22.721H16.434V20.187h4.374V18.942H16.435V16.411h4.371v-1.25h-4.37V12.634h4.358V11.365H16.43V9.083a2.158,2.158,0,0,1,.275.041c2.319.66,4.636,1.326,6.957,1.978a.37.37,0,0,1,.319.432q-.012,12.364-.007,24.73"
          transform="translate(0 0)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default BuildingIcon;
