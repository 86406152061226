import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import schemas from '../../../config/schemas';
import { currencies } from '../../../data/currencies';
import { stepsLabels } from '../../../data/paymentSteps';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import { AppDispatch, RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setCommerce } from '../../../store/slices/commerce.slice';
import { setHotelsDebt } from '../../../store/slices/debt.slice';
import { Commerce } from '../../../types/commerce.type';
import { HotelsDebt } from '../../../types/debt.types';
import { PaymentStep } from '../../../types/enums';
import { Vertical } from '../../../types/verticals.type';
import { commerceHelper } from '../../../utils/commerce.helper';
import FormikAutocompleteField from '../../Formik/FormikAutocompleteField';
import FormikNumberFormat from '../../Formik/FormikNumberFormat';
import FormikSelectField from '../../Formik/FormikSelectField';

function PaymentInformation() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const { setStep, isPaymentLink, updateSubmitDisabled } = usePaymentStepper();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerces: Commerce[] | undefined = vertical?.commerces;
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const hotelsDebt: HotelsDebt = useAppSelector((state: RootState) => state.debt.hotelsDebt);
  const { amount: amountParam } = useParams<{
    amount?: string;
    currency?: string;
  }>();
  const amount = amountParam && +amountParam;

  const submitHotelsDebt = (values: HotelsDebt) => {
    const selectedCommerce: Commerce | undefined = commerces?.find(
      (commerce) => commerce.name === values.hotelName,
    );
    dispatch(setHotelsDebt(values));
    if (selectedCommerce) {
      dispatch(setCommerce(selectedCommerce));
    }
    dispatch(setStep(PaymentStep.PAYMENT_METHODS));
  };

  const initialValues: HotelsDebt = {
    hotelName: commerce?.name || null,
    currency: 'USD',
    amount: amount || hotelsDebt.amount || null,
  };

  if (!commerce && isPaymentLink) return null;

  return (
    <Box id="hotels-form-container">
      <h6>{t(stepsLabels[0])}</h6>
      <Formik
        initialValues={initialValues}
        validationSchema={schemas.HotelsDebtSchema}
        onSubmit={submitHotelsDebt}
        enableReinitialize
        isInitialValid={schemas.HotelsDebtSchema.isValidSync(initialValues)}
      >
        {({ values, isValid }) => {
          updateSubmitDisabled(!isValid);

          return (
            <Form id="hotels-debt-form">
              <FormikAutocompleteField
                name="hotelName"
                fullWidth
                label={t('verticals.hotels.debt-form.labels.hotelName')}
                options={commerceHelper.sortVerticalCommerces(commerces) || []}
                placeholder="Seleccionar"
                value={values.hotelName || commerce?.name}
                disabled={isPaymentLink && !!commerce?.name}
              />
              <FormikSelectField
                name="currency"
                fullWidth
                label={t('verticals.hotels.debt-form.labels.currency')}
                options={[currencies.find((currency) => currency.id === 'USD')!]}
                placeholder={t('verticals.hotels.debt-form.placeholders.currency')}
                disabled
              />
              <FormikNumberFormat
                fullWidth
                name="amount"
                label={t('verticals.hotels.debt-form.labels.amount')}
                disabled={isPaymentLink && !!amount}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default PaymentInformation;
