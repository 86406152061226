import colors from '../../styles/_variables.module.scss';

function CloseIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8934 38.116L49.6066 28.4028L51.3744 30.1706L41.6612 39.8838L51.6066 49.8292L49.8389 51.597L39.8934 41.6516L30.1612 51.3838L28.3934 49.616L38.1257 39.8838L28.6257 30.3838L30.3934 28.616L39.8934 38.116Z"
        fill={colors.yellow}
      />
    </svg>
  );
}

export default CloseIcon;
