import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../Icons/CloseIcon';
import PrimaryButton from '../../PrimaryButton';

interface EditRestaurantDialogProps {
  isDialogOpen: boolean;
  handleCloseDialog: () => void;
  title: string;
  contentText: string;
  continueButtonText?: string;
  onContinue: () => void;
}

function EditRestaurantDialog({
  isDialogOpen,
  handleCloseDialog,
  title,
  contentText,
  continueButtonText,
  onContinue,
}: EditRestaurantDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog} className="edit-restaurant-dialog">
      <DialogTitle>
        <IconButton style={{ float: 'right' }} edge="end" onClick={handleCloseDialog} aria-label="close">
          <CloseIcon />
        </IconButton>
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        <p>{contentText}</p>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={onContinue}>
          {continueButtonText || t('verticals.restaurants.edit-dialog.submit-button')}
        </PrimaryButton>
        <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
          {t('verticals.restaurants.edit-dialog.cancel-button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditRestaurantDialog;
