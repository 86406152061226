import { AppBar, Box, Button, CircularProgress, Divider, SxProps } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import pixLogo from '../assets/PIX-logo.svg';
import logo from '../assets/isologo-paypunta-color.svg';
import leftArrow from '../assets/long-arrow-left.svg';
import usePaymentStepper from '../hooks/usePaymentStepper';
import colors from '../styles/_variables.module.scss';
import { VerticalType, mutationKeys } from '../types/enums';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import PrimaryButton from './PrimaryButton';

interface HeaderProps {
  goBack?(): void;
  submit?(): void;
  onClick?: () => void;
  form?: string;
  hasBack?: boolean;
  hasSubmit?: boolean;
  submitDisabled?: boolean;
  isLoading?: boolean;
  buttonText?: string;
  totalAmount?: string;
  title?: string;
  commissionText?: string;
  hasPIXLogo?: boolean;
  commerceId?: number;
  verticalType?: VerticalType;
}

const styles: SxProps = {
  backgroundColor: colors.white,
  boxShadow: '0 1px 1px 0 rgb(0 0 0 / 10%)',
};

function Header({
  goBack,
  submit,
  form,
  hasBack = true,
  hasSubmit = true,
  submitDisabled,
  isLoading,
  buttonText,
  title,
  totalAmount,
  commissionText,
  hasPIXLogo = false,
  onClick,
  commerceId,
  verticalType,
}: HeaderProps) {
  const { t } = useTranslation();
  const { isSubmitDisabled } = usePaymentStepper();
  const isBeginningPay = !!useIsMutating({ mutationKey: mutationKeys.beginPay });

  return (
    <>
      <AppBar className="header" position="static" sx={styles}>
        <div className="content">
          <div className="logos">
            <img src={logo} alt="logo" />
            {hasPIXLogo && (
              <>
                <Divider orientation="vertical" flexItem sx={{ margin: '0.65rem 0' }} />
                <img src={pixLogo} alt="PIX logo" />
              </>
            )}
          </div>
          <LanguageSelector />
        </div>
      </AppBar>
      <Box className="title">
        <h5>{title}</h5>
        <Box className="buttons">
          {hasBack && (
            <Button
              disabled={isLoading}
              onClick={onClick || goBack}
              variant="outlined"
              color="info"
              sx={{ backgroundColor: colors.white }}
            >
              {buttonText || t('verticals.step-labels.back-button')}
            </Button>
          )}
          {hasSubmit && (
            <PrimaryButton
              disabled={submitDisabled || isSubmitDisabled || isBeginningPay}
              onClick={submit}
              form={form}
            >
              {isLoading || isBeginningPay ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('verticals.step-labels.submit-button')
              )}
            </PrimaryButton>
          )}
        </Box>
      </Box>
      <Box className="floating-bar">
        {totalAmount ? (
          <Box className="floating-total">
            <Box className="text-container">
              <span className="primary-text">Total</span>
              <span className="secondary-text">{commissionText || '(deuda + comisión)'}</span>
            </Box>
            <span className="amount-container">{totalAmount}</span>
          </Box>
        ) : (
          ''
        )}
        <Box className="floating-buttons">
          {hasBack && (
            <Button
              disabled={isLoading}
              onClick={onClick || goBack}
              variant="outlined"
              color="info"
              sx={{ backgroundColor: colors.white }}
            >
              <img src={leftArrow} alt="left-arrow" />
            </Button>
          )}
          {hasSubmit && (
            <PrimaryButton
              disabled={submitDisabled || isSubmitDisabled || isBeginningPay}
              onClick={submit}
              className="floating-continue"
              form={form}
            >
              {isLoading || isBeginningPay ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('verticals.step-labels.submit-button')
              )}
            </PrimaryButton>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Header;
