import { KeyboardArrowDownOutlined, Language } from '@mui/icons-material';
import { Button, Menu, MenuItem, SxProps } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/_variables.module.scss';

interface Languages {
  [key: string]: {
    nativeName: string;
  };
}

const styledButton: SxProps = {
  minWidth: '9.375rem',
  backgroundColor: colors.white,
  padding: '0.4063rem 0.6875rem',
  border: `1px solid ${colors.borderButtonGrey}`,
  color: colors.darkGrey,
  '& .MuiButton-startIcon': {
    color: colors.lightGrey,
  },
  '& .MuiButton-endIcon': {
    color: colors.darkGreyBlue,
  },
  '&:hover': {
    backgroundColor: colors.white,
  },
};

const styledMenu: SxProps = {
  '& .MuiPaper-root': {
    marginTop: '0.5rem',
  },
  '& .MuiMenuItem-root': {
    color: colors.darkGrey,
    minWidth: '9.375rem',
    '&:hover': {
      backgroundColor: colors.lightGrayishOrange,
    },
  },
};

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const lngs: Languages = {
    es: { nativeName: 'Español' },
    en: { nativeName: 'English' },
    br: { nativeName: 'Portuguese' },
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<Language />}
        endIcon={<KeyboardArrowDownOutlined />}
        sx={styledButton}
        disableRipple
      >
        {lngs[i18n.resolvedLanguage!].nativeName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={styledMenu}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} onClick={() => changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
            {lngs[lng].nativeName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LanguageSelector;
