import { useQuery } from '@tanstack/react-query';
import { paymentMethodsService } from '../../services/paymentMethods';
import { VerticalType } from '../../types/enums';
import { PaymentMethods } from '../../types/paymentMethod.types';
import queryKeys from '../../utils/query.helper';

function usePaymentMethods(verticalType?: VerticalType, commerceId?: number) {
  return useQuery<PaymentMethods | undefined>({
    queryKey: queryKeys.paymentMethods.list({ verticalType, commerceId }),
    refetchOnWindowFocus: false,
    queryFn: () => verticalType && paymentMethodsService.getPaymentMethods({ verticalType, commerceId }),
    enabled: !!verticalType && !!commerceId,
  });
}

export default usePaymentMethods;
