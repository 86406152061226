import { StyleSheet } from '@react-pdf/renderer';
import colors from '../styles/_variables.module.scss';

export const RECEIPT_WITH = '250px';

export const commonStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: RECEIPT_WITH,
    fontSize: 12,
    color: colors.greyText,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const styles = StyleSheet.create({
  page: {
    ...commonStyles.column,
    paddingTop: 50,
    justifyContent: 'flex-start',
  },
  imageContainer: {
    ...commonStyles.row,
    justifyContent: 'center',
    marginVertical: 12,
  },
  footerContainer: {
    borderTop: `${0.5} solid ${colors.placeholderGrey}`,
    width: RECEIPT_WITH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    marginTop: 4,
  },
  image: {
    height: 35,
    width: 'auto',
  },
  footerImage: {
    height: 22,
    width: 'auto',
  },
  content: {
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  gap: {
    paddingVertical: 8,
  },
  receiptContainer: {
    border: `${0.5} solid ${colors.placeholderGrey}`,
    padding: 10,
    ...commonStyles.column,
    justifyContent: 'center',
  },

  horizontalChunk: {
    ...commonStyles.row,
    marginVertical: 4,
  },

  horizontalChunkBorder: {
    ...commonStyles.row,
    borderTop: `${0.5} solid ${colors.placeholderGrey}`,
    paddingVertical: 8,
  },
  cardTextRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  totalRow: {
    ...commonStyles.row,
    fontSize: 14,
  },
  detailHeader: {
    ...commonStyles.column,
    width: RECEIPT_WITH,
    marginBottom: 12,
  },
  headerSubTitle: {
    fontSize: 14,
    color: colors.placeholderGrey,
    paddingBottom: 4,
  },
  headerTitle: {
    fontSize: 18,
    color: colors.greyText,
    paddingBottom: 20,
  },
  detailText: {
    fontSize: 12,
    color: colors.greyText,
    marginVertical: 4,
    flexGrow: 1,
  },
  text: {
    fontSize: 12,
    color: colors.placeholderGrey,
    paddingBottom: 4,
  },
  smallText: {
    fontSize: 10,
    color: colors.placeholderGrey,
  },
  totalText: {
    marginTop: 4,
  },
});

export const formatCustomDate = (paymentDate: string): string => {
  const date = new Date(paymentDate);

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat('es-ES', optionsDate).format(date);

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  let formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);

  formattedTime = formattedTime
    .replace(/AM/i, 'AM')
    .replace(/PM/i, 'PM')
    .replace(/\u00a0/g, ' ');

  return `${formattedDate}, ${formattedTime}`;
};
