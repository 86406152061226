import usePaymentStepper from '../../hooks/usePaymentStepper';
import useWindowSize from '../../hooks/useWindowSize';
import UserInformationList from './UserInformationList';
import UserInformationTable from './UserInformationTable';

function UserInformation() {
  const { isMobile } = useWindowSize();
  const { isPaymentLink } = usePaymentStepper();

  return <> {!isPaymentLink && (isMobile ? <UserInformationList /> : <UserInformationTable />)}</>;
}

export default UserInformation;
