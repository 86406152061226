import colors from '../../styles/_variables.module.scss';
import { IconProps } from '../../types/icon.types';

function CutleryIcon({ selected }: IconProps) {
  const fillColor = selected ? colors.primary : colors.secondary;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.21" height="37.861" viewBox="0 0 23.21 37.861">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_1434"
            data-name="Rectángulo 1434"
            width="23.21"
            height="37.861"
            fill={fillColor}
          />
        </clipPath>
      </defs>
      <g id="Grupo_3880" data-name="Grupo 3880" clipPath="url(#clip-path)">
        <path
          id="Trazado_2872"
          data-name="Trazado 2872"
          d="M125.036,26.688c.1-4.636.167-7.972.252-11.308a1.048,1.048,0,0,0-.561-1.037,6.421,6.421,0,0,1-2.779-3.835,9.9,9.9,0,0,1,.407-7.134,6.387,6.387,0,0,1,1.532-2.147,4.154,4.154,0,0,1,5.979.087,7.594,7.594,0,0,1,2.14,4.3,9.572,9.572,0,0,1-1.17,6.819,5.42,5.42,0,0,1-1.974,1.957.927.927,0,0,0-.5.92q.179,7.525.346,15.05c.038,1.681.08,3.362.107,5.043a2,2,0,1,1-3.982-.046c.076-3.323.153-6.646.2-8.669m1.249-1.43h.006q-.115,4.947-.229,9.894a3.168,3.168,0,0,0,.008.538.766.766,0,0,0,.82.654.751.751,0,0,0,.7-.734c.008-.128,0-.257,0-.385q-.22-9.875-.446-19.749A2.193,2.193,0,0,1,128.3,13.3a4.2,4.2,0,0,0,1.581-1.648,8.469,8.469,0,0,0,.88-6.03,6.136,6.136,0,0,0-1.985-3.645A2.853,2.853,0,0,0,125,1.887a5.129,5.129,0,0,0-1.628,2.2,8.759,8.759,0,0,0-.27,6.025,5.2,5.2,0,0,0,2.23,3.165,2.233,2.233,0,0,1,1.18,2.16c-.09,3.272-.154,6.544-.228,9.817"
          transform="translate(-108.97 0)"
          fill={fillColor}
        />
        <path
          id="Trazado_2873"
          data-name="Trazado 2873"
          d="M4.867,32.072q-.1-4.254-.2-8.509a3.9,3.9,0,0,1,1.363-3.179,4.082,4.082,0,0,0,1.421-3.3c-.028-3.427-.01-6.855-.009-10.283a3.492,3.492,0,0,1,.009-.423.6.6,0,0,1,1.167-.1,1.5,1.5,0,0,1,.043.418q0,5.353,0,10.707A4.666,4.666,0,0,1,7.063,21.06a3.357,3.357,0,0,0-1.182,2.822c.167,5.672.29,11.344.426,17.017a1.981,1.981,0,0,1-1.564,2.16,1.959,1.959,0,0,1-2.389-1.98c.025-1.861.082-3.722.127-5.583q.146-6.006.3-12.012a2.839,2.839,0,0,0-1.055-2.3A4.751,4.751,0,0,1,.1,18.421a6.055,6.055,0,0,1-.1-1.071q-.01-5.315,0-10.63a1.605,1.605,0,0,1,.046-.456.55.55,0,0,1,.613-.418.558.558,0,0,1,.547.5,2.593,2.593,0,0,1,.013.423c0,3.479.011,6.958-.006,10.437a3.875,3.875,0,0,0,1.346,3.1A4.114,4.114,0,0,1,4,23.692q-.228,8.547-.422,17.094a3.527,3.527,0,0,0,0,.385.756.756,0,0,0,1.511-.013,1.737,1.737,0,0,0,0-.192q-.107-4.447-.215-8.894H4.867"
          transform="translate(0 -5.242)"
          fill={fillColor}
        />
        <path
          id="Trazado_2874"
          data-name="Trazado 2874"
          d="M25.383,11.053q0,1.906,0,3.811a2.93,2.93,0,0,1-.013.384.57.57,0,0,1-.6.524.556.556,0,0,1-.579-.507,2.422,2.422,0,0,1-.017-.384q0-3.831,0-7.661c0-.051,0-.1,0-.154.011-.474.221-.733.6-.738s.6.254.614.721c0,.09,0,.18,0,.27v3.734"
          transform="translate(-21.683 -5.677)"
          fill={fillColor}
        />
        <path
          id="Trazado_2875"
          data-name="Trazado 2875"
          d="M48.16,11c0-1.335,0-2.669,0-4a.638.638,0,0,1,.388-.684.59.59,0,0,1,.808.469,2.4,2.4,0,0,1,.018.384q0,3.83,0,7.661a2.51,2.51,0,0,1-.018.384.566.566,0,0,1-.617.507.542.542,0,0,1-.562-.523c-.027-.306-.015-.615-.015-.923q0-1.636,0-3.272"
          transform="translate(-43.202 -5.627)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

export default CutleryIcon;
