import { useLayoutEffect, useState } from 'react';

interface Size {
  width: Window['innerWidth'];
  height: Window['innerHeight'];
  isMobile: boolean;
  isLandscape: boolean;
}

function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
    isMobile: false,
    isLandscape: false,
  });

  useLayoutEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isMobile = window.matchMedia('(max-width: 899px)').matches;
      const isLandscape = width > height;

      setWindowSize({ width, height, isMobile, isLandscape });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
