import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { config } from '../../../config/config';
import schemas from '../../../config/schemas';
import { documentTypes } from '../../../data/documentTypes';
import { captchaService } from '../../../services/captcha';
import { AppDispatch, RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setUser } from '../../../store/slices/user.slice';
import { VerticalType } from '../../../types/enums';
import { User } from '../../../types/user.types';
import FormikSelectField from '../../Formik/FormikSelectField';
import FormikTextField from '../../Formik/FormikTextField';
import Navigation from '../../Login/LoginNavigation';

interface LocalState {
  editUserInformation?: boolean;
}

function LoginForm() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const captchaRef: React.MutableRefObject<ReCAPTCHA | null> = useRef(null);
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const selectedUser: User = useAppSelector((state: RootState) => state.user);
  const localState: LocalState = location?.state as LocalState;
  const isEditing: boolean = localState?.editUserInformation || false;

  const submitLogin = async (values: User) => {
    const captchaVerified: boolean = await captchaService.verifyCaptchaToken(captchaToken);
    if (captchaVerified) {
      dispatch(setUser(values));
      navigate(`/${VerticalType.EXPENSES.toLocaleLowerCase()}`);
    } else {
      captchaRef.current?.reset();
    }
  };

  const onGetToken = (value: any) => setCaptchaToken(value);

  const initialValues: User = {
    userName: isEditing ? selectedUser.userName : null,
    email: isEditing ? selectedUser.email : null,
    phone: isEditing ? selectedUser.phone : null,
    documentType: isEditing ? selectedUser.documentType : 'Seleccionar',
    document: isEditing ? selectedUser.document : null,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schemas.UserSchema} onSubmit={submitLogin}>
      <Form className="expenses-form">
        <FormikTextField
          fullWidth
          name="userName"
          label={t('verticals.expenses.login-form.labels.username')}
        />
        <div className="form-grid">
          <FormikTextField fullWidth name="email" label={t('verticals.expenses.login-form.labels.email')} />
          <FormikTextField fullWidth name="phone" label={t('verticals.expenses.login-form.labels.phone')} />
          <FormikSelectField
            name="documentType"
            fullWidth
            label={t('verticals.expenses.login-form.labels.documentType')}
            options={documentTypes}
            placeholder={t('verticals.expenses.login-form.placeholders.documentType')}
          />
          <FormikTextField
            fullWidth
            name="document"
            label={t('verticals.expenses.login-form.labels.document')}
          />
        </div>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={config.captchaSiteKey!}
          onChange={onGetToken}
          className="recaptcha"
        />
        <Navigation isDisabled={!!!captchaToken} />
      </Form>
    </Formik>
  );
}

export default LoginForm;
