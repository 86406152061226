import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PayPuntaOrangeLogo } from '../assets/logo-pp-orange.svg';
import Loading from '../components/Animations/Loading';
import Message from '../components/Message';
import useVerticals from '../hooks/queries/useVerticals';
import usePaymentStepper from '../hooks/usePaymentStepper';
import { AppDispatch, RootState } from '../store/config';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { resetCommerce, setCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep } from '../store/slices/step.slice';
import { resetUser } from '../store/slices/user.slice';
import { resetVertical, setVertical } from '../store/slices/vertical.slice';
import { Commerce } from '../types/commerce.type';
import { VerticalType } from '../types/enums';
import { Vertical } from '../types/verticals.type';
import { verticalHelper } from '../utils/vertical.helper';

function VerticalRoutes() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { data: verticals, isLoading: isLoadingVerticals, isError, error } = useVerticals();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerces: Commerce[] | undefined = vertical?.commerces;
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  // commerceIdentifier can be either numbers if commerceId is used or lowercase letters and hyphens if urlSafeName is used.
  const { commerceIdentifierParam, isPaymentLink } = usePaymentStepper();
  const verticalTypeParam = params.verticalType?.toUpperCase() as VerticalType;

  const errorMessageText =
    (error as Error)?.message ||
    (isPaymentLink && !vertical?.name
      ? t('errors.invalid-link')
      : verticalHelper.getVerticalLinkError(vertical?.verticalType));

  useEffect(() => {
    const verticalChanged = verticalTypeParam !== vertical?.verticalType;
    const commerceChanged = ![
      commerce?.commerceId.toString(),
      commerce?.urlSafeName?.toLocaleLowerCase(),
    ].includes(commerceIdentifierParam);

    if (verticalChanged) {
      dispatch(resetVertical());
      dispatch(resetDebtState());
      dispatch(resetStep());
      dispatch(resetCommerce());
      dispatch(resetUser());
    } else if (isPaymentLink && commerceChanged) {
      dispatch(resetCommerce());
      dispatch(resetDebtState());
      dispatch(resetStep());
    }

    const selectedVertical: Vertical | undefined = verticals?.find(
      (vertical: Vertical) => vertical.verticalType === verticalTypeParam,
    );
    const selectedCommerce: Commerce | undefined = commerces?.find(
      (commerce) =>
        commerce.commerceId.toString() === commerceIdentifierParam ||
        commerce.urlSafeName === commerceIdentifierParam,
    );

    if (selectedVertical) dispatch(setVertical(selectedVertical));
    if (commerceChanged && selectedCommerce) dispatch(setCommerce(selectedCommerce));
  }, [
    commerce,
    commerce?.commerceId,
    commerce?.urlSafeName,
    commerceIdentifierParam,
    commerces,
    dispatch,
    isPaymentLink,
    navigate,
    vertical?.verticalType,
    verticalTypeParam,
    verticals,
  ]);

  useEffect(() => {
    dispatch(resetDebtState());
    dispatch(resetStep());
  }, []);

  if (isLoadingVerticals) {
    return <Loading height={300} width={350} centered />;
  }

  if (
    (isPaymentLink && (!vertical?.name || !commerce?.name)) ||
    !vertical?.enabled ||
    (commerce && !commerce?.enabled) ||
    isError
  ) {
    return (
      <Message
        id="error-commerce"
        payPuntaLogo={<PayPuntaOrangeLogo />}
        title={t('errors.generic')}
        text={errorMessageText}
      />
    );
  }

  return <Outlet />;
}

export default VerticalRoutes;
