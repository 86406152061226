import colors from '../../styles/_variables.module.scss';
import { IconProps } from '../../types/icon.types';

function HotelIcon({ selected }: IconProps) {
  const fillColor = selected ? colors.primary : colors.secondary;

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="39.815" height="39.708" viewBox="0 0 39.815 39.708">
        <g id="icon-hotel" transform="translate(-9.76 -9.85)">
          <path
            id="Unión_6"
            data-name="Unión 6"
            d="M-2507.06-1011.435a.644.644,0,0,1-.643-.643.644.644,0,0,1,.643-.643h1.39v-1.392a2.58,2.58,0,0,1-1.926-2.487v-1.471a2.571,2.571,0,0,1,2.569-2.569,2.571,2.571,0,0,1,2.569,2.569v1.471a2.579,2.579,0,0,1-1.926,2.487v1.392h2.8v-25.752h-.419a.644.644,0,0,1-.643-.643v-3.073a.643.643,0,0,1,.643-.643h27.707a.643.643,0,0,1,.643.643v3.073a.644.644,0,0,1-.643.643h-.419v13.371h1.264a.644.644,0,0,1,.643.643v3.119a.644.644,0,0,1-.643.643h-.38v7.977h2.711v-1.392a2.58,2.58,0,0,1-1.926-2.487v-1.471a2.571,2.571,0,0,1,2.569-2.569,2.571,2.571,0,0,1,2.569,2.569v1.471a2.578,2.578,0,0,1-1.926,2.487v1.392h1.31a.644.644,0,0,1,.643.643.644.644,0,0,1-.643.643Zm29.16-7.962a.644.644,0,0,1,.643.643v6.034h2.137v-7.977h-12.57v7.977h2.124v-6.034a.643.643,0,0,1,.643-.643Zm-2.869,6.677h2.227v-5.392h-2.227Zm-3.512,0h2.227v-5.392h-2.227Zm-16.024,0h11.328v-7.977h-.391a.643.643,0,0,1-.643-.643v-3.119a.643.643,0,0,1,.643-.643h13.36v-13.371h-24.3Zm28.538-5.351v1.471a1.285,1.285,0,0,0,1.283,1.283,1.285,1.285,0,0,0,1.283-1.283v-1.471a1.285,1.285,0,0,0-1.283-1.283A1.285,1.285,0,0,0-2471.766-1018.071Zm-34.545,0v1.471a1.285,1.285,0,0,0,1.283,1.283,1.285,1.285,0,0,0,1.283-1.283v-1.471a1.285,1.285,0,0,0-1.283-1.283A1.285,1.285,0,0,0-2506.311-1018.071Zm17.587-3.911h14.624v-1.833h-14.624Zm-12.642-17.776h26.422v-1.787h-26.422Z"
            transform="translate(2517.462 1060.994)"
            fill={fillColor}
          />
          <path
            id="Unión_10"
            data-name="Unión 10"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2538.17 1067.308)"
            fill={fillColor}
          />
          <path
            id="Unión_11"
            data-name="Unión 11"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2541.625 1067.308)"
            fill={fillColor}
          />
          <path
            id="Unión_12"
            data-name="Unión 12"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2545.079 1067.308)"
            fill={fillColor}
          />
          <path
            id="Unión_15"
            data-name="Unión 15"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2538.17 1072.979)"
            fill={fillColor}
          />
          <path
            id="Unión_14"
            data-name="Unión 14"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2541.625 1072.979)"
            fill={fillColor}
          />
          <path
            id="Unión_13"
            data-name="Unión 13"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2545.079 1072.979)"
            fill={fillColor}
          />
          <path
            id="Unión_7"
            data-name="Unión 7"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2527.802 1067.308)"
            fill={fillColor}
          />
          <path
            id="Unión_8"
            data-name="Unión 8"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2531.257 1067.308)"
            fill={fillColor}
          />
          <path
            id="Unión_9"
            data-name="Unión 9"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.644.644,0,0,1,.644.643v2.153a.644.644,0,0,1-.644.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2534.71 1067.308)"
            fill={fillColor}
          />
          <path
            id="Unión_18"
            data-name="Unión 18"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2527.802 1072.979)"
            fill={fillColor}
          />
          <path
            id="Unión_17"
            data-name="Unión 17"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2531.257 1072.979)"
            fill={fillColor}
          />
          <path
            id="Unión_20"
            data-name="Unión 20"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2527.802 1078.651)"
            fill={fillColor}
          />
          <path
            id="Unión_19"
            data-name="Unión 19"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.643.643,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2531.257 1078.651)"
            fill={fillColor}
          />
          <path
            id="Unión_21"
            data-name="Unión 21"
            d="M-2507.7-1040.036v-2.153a.644.644,0,0,1,.643-.643.644.644,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2527.802 1084.322)"
            fill={fillColor}
          />
          <path
            id="Unión_22"
            data-name="Unión 22"
            d="M-2507.7-1040.036v-2.153a.644.644,0,0,1,.643-.643.644.644,0,0,1,.643.643v2.153a.643.643,0,0,1-.643.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2531.257 1084.322)"
            fill={fillColor}
          />
          <path
            id="Unión_16"
            data-name="Unión 16"
            d="M-2507.7-1040.036v-2.153a.643.643,0,0,1,.643-.643.644.644,0,0,1,.644.643v2.153a.644.644,0,0,1-.644.643A.643.643,0,0,1-2507.7-1040.036Z"
            transform="translate(2534.71 1072.979)"
            fill={fillColor}
          />
          <path
            id="Unión_5"
            data-name="Unión 5"
            d="M-2502.618-1036.1l-1.548-.625-1.548.626a.653.653,0,0,1-.241.047.637.637,0,0,1-.377-.123.638.638,0,0,1-.264-.564l.117-1.667-1.073-1.278a.645.645,0,0,1-.119-.611.637.637,0,0,1,.455-.426l1.621-.4.884-1.416a.632.632,0,0,1,.545-.287.632.632,0,0,1,.545.287l.884,1.416,1.621.4a.641.641,0,0,1,.456.425.637.637,0,0,1-.119.612l-1.074,1.278.117,1.667a.638.638,0,0,1-.264.564.636.636,0,0,1-.377.123A.638.638,0,0,1-2502.618-1036.1Zm-1.306-1.914.834.338-.063-.9a.635.635,0,0,1,.149-.458l.58-.689-.875-.218a.642.642,0,0,1-.39-.283l-.477-.764-.477.764a.641.641,0,0,1-.39.283l-.873.218.579.689a.643.643,0,0,1,.148.458l-.063.9.835-.338a.641.641,0,0,1,.24-.047A.65.65,0,0,1-2503.924-1038.017Z"
            transform="translate(2517.548 1052.697)"
            fill={fillColor}
          />
          <path
            id="Unión_4"
            data-name="Unión 4"
            d="M-2502.616-1036.087l-1.547-.625-1.549.625a.628.628,0,0,1-.24.047.637.637,0,0,1-.378-.122.638.638,0,0,1-.264-.565l.117-1.666-1.074-1.279a.637.637,0,0,1-.119-.611.638.638,0,0,1,.456-.425l1.62-.4.886-1.417a.637.637,0,0,1,.544-.3.637.637,0,0,1,.544.3l.886,1.417,1.62.4a.637.637,0,0,1,.455.425.635.635,0,0,1-.119.611l-1.073,1.279.116,1.667a.638.638,0,0,1-.264.564.633.633,0,0,1-.376.122A.636.636,0,0,1-2502.616-1036.087Zm-1.306-1.913.834.338-.063-.9a.635.635,0,0,1,.148-.458l.581-.689-.875-.218a.637.637,0,0,1-.39-.283l-.477-.764-.478.765a.639.639,0,0,1-.389.283l-.874.218.579.69a.642.642,0,0,1,.148.458l-.063.9.835-.338a.637.637,0,0,1,.24-.047A.641.641,0,0,1-2503.922-1038Z"
            transform="translate(2525.704 1052.681)"
            fill={fillColor}
          />
          <path
            id="Unión_3"
            data-name="Unión 3"
            d="M-2502.616-1036.1l-1.548-.625-1.549.626a.652.652,0,0,1-.241.047.637.637,0,0,1-.377-.123.637.637,0,0,1-.264-.564l.117-1.667-1.074-1.278a.638.638,0,0,1-.119-.611.638.638,0,0,1,.456-.426l1.62-.4.885-1.417a.633.633,0,0,1,.545-.286.629.629,0,0,1,.544.287l.885,1.416,1.621.4a.639.639,0,0,1,.455.426.636.636,0,0,1-.118.61l-1.074,1.279.117,1.667a.637.637,0,0,1-.264.564.637.637,0,0,1-.378.123A.63.63,0,0,1-2502.616-1036.1Zm-1.308-1.913.834.337-.063-.9a.638.638,0,0,1,.149-.458l.58-.69-.874-.218a.637.637,0,0,1-.391-.283l-.476-.763-.478.764a.64.64,0,0,1-.389.283l-.875.218.579.69a.642.642,0,0,1,.149.458l-.063.9.834-.337a.64.64,0,0,1,.242-.047A.631.631,0,0,1-2503.923-1038.016Z"
            transform="translate(2533.861 1052.696)"
            fill={fillColor}
          />
          <path
            id="Unión_2"
            data-name="Unión 2"
            d="M-2506.332-1036.177a.64.64,0,0,1-.264-.565l.116-1.667-1.073-1.278a.646.646,0,0,1-.119-.612.638.638,0,0,1,.455-.425l1.621-.4.884-1.417a.633.633,0,0,1,.545-.286.631.631,0,0,1,.545.286l.885,1.416,1.621.4a.641.641,0,0,1,.456.426.637.637,0,0,1-.119.611l-1.074,1.279.117,1.666a.639.639,0,0,1-.264.565.635.635,0,0,1-.377.122.637.637,0,0,1-.242-.047l-1.548-.625-1.547.625a.642.642,0,0,1-.242.048A.635.635,0,0,1-2506.332-1036.177Zm2.407-1.84.834.338-.063-.9a.637.637,0,0,1,.149-.458l.58-.689-.874-.218a.641.641,0,0,1-.39-.283l-.478-.764-.477.764a.642.642,0,0,1-.389.283l-.875.218.58.689a.642.642,0,0,1,.147.458l-.063.9.835-.338a.64.64,0,0,1,.241-.047A.644.644,0,0,1-2503.924-1038.017Z"
            transform="translate(2542.019 1052.697)"
            fill={fillColor}
          />
          <path
            id="Unión_1"
            data-name="Unión 1"
            d="M-2502.616-1036.1l-1.548-.625-1.549.626a.652.652,0,0,1-.241.047.642.642,0,0,1-.378-.123.639.639,0,0,1-.263-.564l.117-1.667-1.074-1.278a.638.638,0,0,1-.119-.611.638.638,0,0,1,.456-.426l1.62-.4.886-1.417a.63.63,0,0,1,.544-.286.629.629,0,0,1,.544.287l.885,1.416,1.621.4a.639.639,0,0,1,.455.426.636.636,0,0,1-.118.61l-1.074,1.279.117,1.667a.637.637,0,0,1-.264.564.637.637,0,0,1-.378.123A.63.63,0,0,1-2502.616-1036.1Zm-1.307-1.913.834.337-.063-.9a.633.633,0,0,1,.148-.458l.58-.69-.874-.218a.638.638,0,0,1-.39-.283l-.477-.763-.477.764a.639.639,0,0,1-.39.283l-.875.218.58.69a.642.642,0,0,1,.148.458l-.063.9.835-.337a.639.639,0,0,1,.242-.047A.633.633,0,0,1-2503.922-1038.016Z"
            transform="translate(2550.175 1052.696)"
            fill={fillColor}
          />
        </g>
      </svg>
    </>
  );
}

export default HotelIcon;
