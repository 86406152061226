import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Vertical } from '../../types/verticals.type';

export interface VerticalState {
  vertical: Vertical | null;
}

const initialState: VerticalState = {
  vertical: null,
};

const verticalSlice = createSlice({
  name: 'vertical',
  initialState,
  reducers: {
    setVertical: (state: VerticalState, action: PayloadAction<Vertical | null>) => {
      state.vertical = action.payload;
    },
    resetVertical: () => initialState,
  },
  extraReducers: {},
});

const { reducer } = verticalSlice;

export const { setVertical, resetVertical } = verticalSlice.actions;

export default reducer;
