import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../../hooks/useWindowSize';
import { RootState } from '../../../../store/config';
import { useAppSelector } from '../../../../store/hooks';
import { AmountState } from '../../../../store/slices/amount.slice';
import { Commerce } from '../../../../types/commerce.type';
import { RestaurantsDebt } from '../../../../types/debt.types';
import { Issuer } from '../../../../types/paymentMethod.types';
import { amountHelper } from '../../../../utils/amount.helper';
import Loading from '../../../Animations/Loading';
import SinglePaymentMethod from '../../../SinglePaymentMethod';

function PaymentMethodsData() {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const restaurantsDebt: RestaurantsDebt = useAppSelector((state: RootState) => state.debt.restaurantsDebt);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const debt: RestaurantsDebt = useAppSelector((state: RootState) => state.debt.restaurantsDebt);
  const pixIssuer: Issuer | null = useAppSelector((state: RootState) => state.issuer.issuer);
  const {
    amount: amountDetail,
    loading: isLoadingAmount,
    errorMessage: amountError,
  }: AmountState = useAppSelector((state: RootState) => state.amount);

  const getRowData = (subtitle: string, body: string | number | undefined) => {
    return (
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography className="row-title">{subtitle}</Typography>
        <Typography className="row-data">{body}</Typography>
      </Stack>
    );
  };

  const totalFormattedWithCurrency =
    amountHelper.getRestaurantTotalFormatted(debt.currency, amountDetail) || '-';

  if (isLoadingAmount) return <Loading centered />;

  return (
    <>
      {isMobile && <SinglePaymentMethod issuer={pixIssuer} />}
      {getRowData(t('verticals.restaurants.payment-detail.data.name'), commerce?.name)}
      {getRowData(
        t('verticals.restaurants.payment-detail.data.restaurantEmployee'),
        commerce?.restaurantEmployee,
      )}
      {restaurantsDebt.invoice &&
        getRowData(t('verticals.restaurants.payment-detail.data.invoice'), restaurantsDebt.invoice)}
      <Divider sx={{ mb: 2 }} />
      {getRowData(
        t('verticals.restaurants.payment-detail.data.currency'),
        amountHelper.getCurrencyText(restaurantsDebt.currency),
      )}
      {restaurantsDebt.tipPercentage
        ? getRowData(
            t('verticals.restaurants.payment-detail.data.tipPercentage'),
            `${restaurantsDebt.tipPercentage}%`,
          )
        : getRowData(t('verticals.restaurants.payment-detail.data.tipPercentage'), '0,00')}
      {getRowData(
        t('verticals.restaurants.payment-detail.data.amount'),
        amountHelper.getCurrency(restaurantsDebt.currency) +
          ' ' +
          amountHelper.getAmountFormatted(restaurantsDebt.amount),
      )}
      {!isMobile &&
        (!!pixIssuer?.commission || !!pixIssuer?.fixedCommissionUsd || !!pixIssuer?.fixedCommissionUyu) && (
          <>
            {getRowData(
              t('verticals.restaurants.payment-detail.data.commission'),
              amountHelper.getCommissionText(
                pixIssuer?.commission,
                amountHelper.getFixedCommission(restaurantsDebt.currency, pixIssuer),
                restaurantsDebt.currency,
                pixIssuer?.plexoIssuerId,
              ),
            )}
          </>
        )}
      {getRowData(
        t('verticals.restaurants.payment-detail.data.vatReturn'),
        '- ' +
          amountHelper.getCurrency(restaurantsDebt.currency) +
          ' ' +
          amountHelper.getVatReturnAmount(debt.currency, amountDetail),
      )}
      {!isMobile && (
        <>
          <Divider sx={{ mb: 2 }} />
          {amountError ? (
            <p>* Error al obtener el total</p>
          ) : (
            <div className="total-row">
              {getRowData(t('verticals.restaurants.payment-detail.data.total'), totalFormattedWithCurrency)}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PaymentMethodsData;
