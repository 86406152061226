const sanitizePathnameForGtm = (pathname: string): string => {
  const pathnameParts = pathname.split('/').filter(Boolean);

  let sanitizedPathname = pathnameParts[0] || '';

  if (pathnameParts.length > 1) {
    sanitizedPathname += pathnameParts[1] === 'login' ? `_login` : `_commerceID`;
  }

  if (pathnameParts.length > 2) {
    sanitizedPathname += pathnameParts[2] === 'login' ? `_login` : `_currency`;
  }

  if (pathnameParts.length > 3) {
    sanitizedPathname += `_amount`;
  }

  // This is a workaround to avoid future conflicts with GTM tags if the pathname has more than 4 parts
  if (pathnameParts.length > 4) {
    for (let i = 4; i < pathnameParts.length; i++) {
      sanitizedPathname += `_tagToDefine`;
    }
  }

  return sanitizedPathname;
};

export const pathnameHelper = {
  sanitizePathnameForGtm,
};
