import { Alert, Snackbar } from '@mui/material';
import colors from '../../styles/_variables.module.scss';

interface CustomSnackbarProps {
  message: string;
  severity: 'error' | 'success' | 'info' | 'warning';
  isOpen: boolean;
  handleClose: () => void;
  isMobile?: boolean;
}

function CustomSnackbar({ message, severity, isOpen, handleClose, isMobile = false }: CustomSnackbarProps) {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={
        !isMobile ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'center' }
      }
      sx={{
        width: isMobile ? '100%' : '55%',
        border: `1px solid ${colors.red}`,
        left: 0,
        right: 0,
      }}
    >
      <Alert
        sx={{
          textAlign: `${isMobile && 'center'}`,
          width: '100%',
          color: colors.red,
          fontWeight: 600,
          '& .MuiAlert-icon': { color: colors.red },
          '& .MuiIconButton-root': { opacity: 0.16 },
        }}
        severity={severity}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
