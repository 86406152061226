/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CurvedArrow } from '../assets/curved-arrow.svg';
import { ReactComponent as PayPuntaOrangeLogo } from '../assets/logo-pp-orange.svg';
import Message from '../components/Message';
import { config } from '../config/config';
import { RootState } from '../store/config';
import { useAppSelector } from '../store/hooks';
import { VerticalType } from '../types/enums';
import { PaymentResponse } from '../types/paymentResult.types';
import { Vertical } from '../types/verticals.type';

function Error() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paymentError: PaymentResponse | null = useAppSelector((state: RootState) => state.payment.payment);
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const isExpenses: boolean = vertical?.verticalType === VerticalType.EXPENSES;

  const title: string = t('errors.errorTitle');
  const expensesText: string = !isExpenses ? t('errors.expensesText') : '';
  const paymentErrorText: JSX.Element = (
    <Trans
      i18nKey="errors.paymentErrorText"
      values={{
        expensesText: expensesText,
        contactUrl: config.paypuntaLandingContactUrl,
        paymentId: paymentError?.paymentId || '-',
      }}
      components={{ br: <br />, strong: <strong />, a: <a href={config.paypuntaLandingContactUrl} /> }}
    />
  );

  useEffect(() => {
    if (!paymentError?.paymentId) navigate('/');
  }, [navigate, paymentError?.paymentId]);

  return (
    <Message
      id="error-payment"
      payPuntaLogo={<PayPuntaOrangeLogo />}
      title={title}
      text={paymentErrorText}
      retryIcon={<CurvedArrow />}
      retryText={t('errors.retryText')}
      retryUrl={`/${paymentError?.verticalType}/login`}
    />
  );
}

export default Error;
