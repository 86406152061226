import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import schemas from '../../../config/schemas';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import { AppDispatch, RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setCommerce } from '../../../store/slices/commerce.slice';
import { Commerce } from '../../../types/commerce.type';
import { VerticalType } from '../../../types/enums';
import { Restaurant } from '../../../types/restaurant.type';
import { Vertical } from '../../../types/verticals.type';
import { commerceHelper } from '../../../utils/commerce.helper';
import FormikAutocompleteField from '../../Formik/FormikAutocompleteField';
import FormikTextField from '../../Formik/FormikTextField';
import Navigation from '../../Login/LoginNavigation';

function LoginForm() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const commerces: Commerce[] | undefined = vertical?.commerces;
  const { isPaymentLink, commerceIdentifierParam } = usePaymentStepper();

  const initialValues: Restaurant = {
    restaurantName: commerce?.name || null,
    restaurantEmployee: commerce?.restaurantEmployee || null,
  };

  const submitCommerce = async (values: Restaurant) => {
    const selectedCommerce: Commerce | undefined = commerces?.find(
      (commerce) => commerce.name === values.restaurantName,
    );
    if (selectedCommerce && values.restaurantEmployee) {
      const updatedCommerce: Commerce = {
        ...selectedCommerce,
        restaurantEmployee: values.restaurantEmployee,
      };
      dispatch(setCommerce(updatedCommerce));
      navigate(
        `/${VerticalType.RESTAURANTS.toLocaleLowerCase()}${
          isPaymentLink ? `/${commerceIdentifierParam}` : ''
        }`,
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemas.RestaurantSchema}
      onSubmit={submitCommerce}
    >
      {({ values }) => (
        <Form className="restaurants-form">
          <>
            <FormikAutocompleteField
              name="restaurantName"
              fullWidth
              label={t('verticals.restaurants.login-form.labels.restaurantName')}
              options={commerceHelper.sortVerticalCommerces(commerces) || []}
              disabled={isPaymentLink}
              placeholder={t('verticals.restaurants.login-form.placeholders.restaurantName')}
              value={commerce?.name || values.restaurantName || undefined}
            />
            <FormikTextField
              fullWidth
              name="restaurantEmployee"
              label={t('verticals.restaurants.login-form.labels.restaurantEmployee')}
            />
            <Navigation
              hasEditDialog={!!commerce?.name || !!values.restaurantEmployee}
              hasReturnButton={!isPaymentLink}
              isDisabled={!values.restaurantName || !values.restaurantEmployee}
            />
          </>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
