import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import schemas from '../../config/schemas';
import { linkPaymentData } from '../../data/linkPayment';
import { RootState } from '../../store/config';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setStep } from '../../store/slices/step.slice';
import { resetUser, setUser } from '../../store/slices/user.slice';
import { PaymentStep } from '../../types/enums';
import { Issuer } from '../../types/paymentMethod.types';
import { User } from '../../types/user.types';
import FormikTextField from '../Formik/FormikTextField';

function EmailStep() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const issuer: Issuer | null = useAppSelector((state: RootState) => state.issuer.issuer);

  const handleOnChange = (email: string) => {
    const paymentLinkUser = { ...linkPaymentData, email: email || linkPaymentData.email };
    dispatch(setUser(paymentLinkUser));
  };

  const initialValues: Pick<User, 'email'> = {
    email: '',
  };

  useEffect(() => {
    dispatch(resetUser());
  }, [dispatch]);

  if (!issuer?.plexoIssuerId) dispatch(setStep(PaymentStep.PAYMENT_METHODS));

  return (
    <Box className="email-step">
      <Box className="email-container">
        <h6>{t('verticals.step-labels.email.title')}</h6>
        <p>{t('verticals.step-labels.email.subtitle')}</p>
        <Formik
          initialValues={initialValues}
          validationSchema={schemas.EmailSchema}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values }) => (
            <Form id="email-form">
              <FormikTextField
                fullWidth
                name="email"
                label={t('verticals.step-labels.email.label')}
                type="text"
                inputMode="text"
                onChange={(e) => handleOnChange(e.target.value)}
                value={values.email}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default EmailStep;
