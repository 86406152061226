import UserInformation from '../UserInformation/UserInformation';

interface PaymentInformationStepProps {
  children: JSX.Element;
}

function PaymentInformationStep({ children }: PaymentInformationStepProps) {
  return (
    <>
      <UserInformation />
      {children}
    </>
  );
}

export default PaymentInformationStep;
