import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import usePaymentMethods from '../../../../hooks/queries/usePaymentMethods';
import useWindowSize from '../../../../hooks/useWindowSize';
import { VerticalType } from '../../../../types/enums';
import { Issuer } from '../../../../types/paymentMethod.types';
import { amountHelper } from '../../../../utils/amount.helper';
import Loading from '../../../Animations/Loading';
import SinglePaymentMethod from '../../../SinglePaymentMethod';

interface PaymentDetailsProps {
  tipPercentage: number;
  currency: string;
  restaurantId?: number;
}

function PaymentDetails({ tipPercentage, currency, restaurantId }: PaymentDetailsProps) {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const {
    data: paymentMethods,
    isLoading,
    isError,
  } = usePaymentMethods(VerticalType.RESTAURANTS, restaurantId);
  const pixIssuer: Issuer | undefined = paymentMethods?.issuers[0];
  const commissionText = amountHelper.getCommissionText(
    pixIssuer?.commission,
    amountHelper.getFixedCommission(currency, pixIssuer),
    currency,
    pixIssuer?.plexoIssuerId,
  );

  return (
    <Box className="details commission-detail">
      {isLoading ? (
        <Loading centered />
      ) : isError ? (
        <p>{t('errors.loading-payment-method')}</p>
      ) : paymentMethods?.issuers.length === 0 ? (
        <p>*{t('errors.no-payment-methods')}</p>
      ) : (
        <>
          {/* PIX is the only issuer available for Restaurants vertical */}
          <SinglePaymentMethod issuer={pixIssuer} />
          <Box className="row">{!isMobile && <h6>{t('verticals.restaurants.payment-detail.title')}</h6>}</Box>
          <Box className="row">
            <Typography variant="body2">{t('verticals.restaurants.payment-detail.tip')}</Typography>
            <Typography variant="body2">{tipPercentage ? `${tipPercentage}%` : '0'}</Typography>
          </Box>
          <Box className="row ">
            <Typography variant="body2">{t('verticals.restaurants.payment-detail.commission')}</Typography>
            <Typography variant="body2">{commissionText}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default PaymentDetails;
