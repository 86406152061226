import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import leftArrow from '../../assets/long-arrow-left.svg';
import { AppDispatch } from '../../store/config';
import { useAppDispatch } from '../../store/hooks';
import { resetCommerce } from '../../store/slices/commerce.slice';
import { resetDebtState } from '../../store/slices/debt.slice';
import { resetStep } from '../../store/slices/step.slice';
import EditRestaurantDialog from '../Verticals/Restaurants/EditRestaurantDialog';

interface LoginNavigationProps {
  isDisabled?: boolean;
  hasEditDialog?: boolean;
  hasReturnButton?: boolean;
}

function LoginNavigation({
  isDisabled,
  hasEditDialog = false,
  hasReturnButton = true,
}: LoginNavigationProps) {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleDialogToggle = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleResetRestaurant = () => {
    dispatch(resetCommerce());
    dispatch(resetDebtState());
    dispatch(resetStep());
    navigate('/');
  };

  return (
    <>
      <div className="navigation-section">
        {hasReturnButton && (
          <Button
            onClick={hasEditDialog ? handleDialogToggle : handleResetRestaurant}
            variant="outlined"
            color="info"
            className="go-back-button"
          >
            <img src={leftArrow} alt="left-arrow" className="arrow" />
            <span className="text">{t('verticals.login-back-button')}</span>
          </Button>
        )}
        <Button variant="contained" type="submit" disabled={isDisabled} className="submit-button">
          {t('verticals.login-submit-button')}
        </Button>
      </div>
      {hasEditDialog && (
        <EditRestaurantDialog
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleDialogToggle}
          title={t('verticals.restaurants.edit-dialog.title')}
          contentText={t('verticals.restaurants.edit-dialog.content')}
          onContinue={handleResetRestaurant}
        />
      )}
    </>
  );
}

export default LoginNavigation;
