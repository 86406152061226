import Lottie from 'lottie-react';
import animationData from '../../assets/lotties/PP-loader.json';

export interface LoadingProps {
  fullscreen?: boolean;
  centered?: boolean;
  height?: number;
  width?: number;
  text?: string;
}

function Loading({ fullscreen = false, centered = false, height, width, text }: LoadingProps) {
  const animation = (
    <Lottie
      animationData={animationData}
      loop={true}
      style={{ height: height || 250, width: width || 300 }}
    />
  );

  return (
    <div
      className={
        fullscreen ? 'fullscreen-animation-container' : centered ? 'centered-animation-container' : ''
      }
    >
      {animation}
      {text}
    </div>
  );
}

export default Loading;
